import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Home.css";
import IcoMenu from "../../Imagenes/rocas-002.svg";
import icoCcollect from "../../Imagenes/rocas-003.svg";
import IcoBookings from "../../Imagenes/rocas-004.svg";
import IcoBranch from "../../Imagenes/ico_branch.svg";
import IcoDelivery from "../../Imagenes/rocas-001.svg";

import IcoFacebook from "../../Imagenes/facebook.svg";
import IcoInstragram from "../../Imagenes/instagram.svg";
import IcoLinkedIn from "../../Imagenes/linkedIn.svg";

export const NewHome3 = ({ backgroundColor, imagenHead, statusButtons,socialButtons }) => {
  let navigate = useNavigate();
  localStorage.setItem("mostrarPopUp", true);
  const onLinkClick = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 1);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioMesa");
  };
  const OnDelovery = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 0);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioDomicilio");
  };
  const OnMenu = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 3);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioMesa");
  };

  const OnBooking = (e) => {
    e.preventDefault();
    const nuevaURL =
      "https://reservas.pleizt.co/" + window.location.hostname.split(".")[0];
    // Redireccionar a la nueva URL
    window.location.href = nuevaURL;
  };

  return (
    <React.Fragment>
      <div
        className="container-new-principal-home"
        style={{
          background: backgroundColor,
        }}
      >
        <div className="container-imagen-cabecera-alternativo">
          <img
            src={imagenHead}
            alt="imagenHead"
            className="head-new-home"
          ></img>
        </div>
        <section className="container-options-home">
          {(() => {
            if (statusButtons.delivery) {
              return (
                <button onClick={OnDelovery} className="button-new-home">
                  <p className="title-metodo-pago">
                    <img
                      src={IcoDelivery}
                      alt="imagenCabecera"
                      className="button-img-new-home"
                    ></img>
                  </p>
                  <p className="button-title-new-home">¡Pide a Domicilio!</p>
                </button>
              );
            }
          })()}

          {(() => {
            if (statusButtons.order) {
              return (
                <button onClick={onLinkClick} className="button-new-home">
                  <p className="title-metodo-pago">
                    <img
                      src={icoCcollect}
                      alt="imagenCabecera"
                      className="button-img-new-home"
                    ></img>
                  </p>
                  <p className="button-title-new-home">Pedir y Recoger</p>
                </button>
              );
            }
          })()}

          {(() => {
            if (statusButtons.menu) {
              return (
                <button onClick={OnMenu} className="button-new-home">
                  <p className="title-metodo-pago">
                    <img
                      src={IcoMenu}
                      alt="imagenCabecera"
                      className="button-img-new-home"
                    ></img>
                  </p>
                  <p className="button-title-new-home">
                    Ver la Carta
                  </p>
                </button>
              );
            }
          })()}

          {(() => {
            if (statusButtons.booking) {
              if (
                window.location.hostname.split(".")[0] === "restaurantelasrocas"
              ) {
                return (
                  <button onClick={OnBooking} className="button-new-home">
                    <p className="title-metodo-pago">
                      <img
                        src={IcoBookings}
                        alt="imagenCabecera"
                        className="button-img-new-home"
                      ></img>
                    </p>
                    <p className="button-title-new-home">Reservar una Mesa</p>
                  </button>
                );
              } else if (
                window.location.hostname.split(".")[0] === "new-lasrocas"
              ) {
                return (
                  <button onClick={OnBooking} className="button-new-home">
                    <p className="title-metodo-pago">
                      <img
                        src={IcoBookings}
                        alt="imagenCabecera"
                        className="button-img-new-home"
                      ></img>
                    </p>
                    <p className="button-title-new-home">Reservar</p>
                  </button>
                );
              }
            }
          })()}
          {/*   <button onClick={OnDelovery} className="button-new-home">
            <p className="title-metodo-pago">
              <img
                src={IcoBranch}
                alt="imagenCabecera"
                className="button-img-new-home"
              ></img>
            </p>
            <p className="button-title-new-home">Nuestros puntos</p>
          </button>*/}
        </section>
      </div>
      <footer style={footerStyle}>
      <footer style={footerStyle}>
        {socialButtons?.map((item, i) => {
          return (
            <Link to={item.link}>
              <img src={item.img} alt={item.name} style={imageStyle} />
            </Link>
          );
        })}
      </footer>
      </footer>
    </React.Fragment>
  );
};
const footerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
};

const buttonStyle = {
  margin: "0 10px",
  padding: "8px",
  border: "none",
  background: "transparent",
  cursor: "pointer",
};

const imageStyle = {
  width: "28px", // ajusta el ancho según tus necesidades
  height: "28px", // ajusta la altura según tus necesidades
};
