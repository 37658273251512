import React, { useEffect, useState } from "react";

import "./Home.css";
import HeadComercio from "../../Imagenes/comercio_head.svg";

//import { NewHome1 } from "./NewHome1";
import { NewHome2 } from "./NewHome2";
import { NewHome3 } from "./NewHome3";
import { Splash } from "./Splash";
import { NewHomeError } from "./NewHomeError";
export const NewHome = () => {
  const [showSplash, setShowSplash] = useState(true);
  const urlBase = `https://dev-api.menu.iguarayalabs.com`;
  const pleiztUuid = window.location.hostname.split(".")[0];
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [imagenHead, setImagenHead] = useState(HeadComercio);
  const [errorStatus, setErrorStatus] = useState("error");
  const [buttons, setButtons] = useState([]);
  const [social, setSocial] = useState([]);

  const obtenerListaTiendas = async () => {
    const home = urlBase + `/app`;
    localStorage.setItem("pleiztUuid", pleiztUuid);
    const respuesta = await fetch(home, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": pleiztUuid,
        //"pleizt-uuid-new": pleiztUuid,
      }),
    }).then((respuesta) => respuesta.json());
    setErrorStatus(respuesta.success);
    if (respuesta.success === "success") {
      setImagenHead(respuesta.properties.img_home);
      setBackgroundColor(respuesta.properties.color_background);
      
      localStorage.removeItem("infoProductoSeleccionado");
      localStorage.removeItem("uuidShop");
      localStorage.removeItem("typeSend");
      
      localStorage.setItem("colorBoton", respuesta.properties.color_botton);
      localStorage.setItem("colorHover", respuesta.properties.color_hover);
      setButtons(respuesta.data);
      setSocial(respuesta.data.social)
    }
  };

  useEffect(() => {
    obtenerListaTiendas();
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
      document.body.style.background = "";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      {showSplash ? (
        <div className="splash-screen">
          <Splash></Splash>
        </div>
      ) : (
        <React.Fragment>
          {(() => {
            if (pleiztUuid === "restaurantelasrocas") {
              // return <NewHome1></NewHome1>;
              return (
                <NewHome3
                  backgroundColor={backgroundColor}
                  imagenHead={imagenHead}
                  statusButtons={buttons}
                  socialButtons={social}
                ></NewHome3>
              );
            } else if (pleiztUuid === "new-lasrocas") {
              // return <NewHome1></NewHome1>;
              return (
                <NewHome3
                  backgroundColor={backgroundColor}
                  imagenHead={imagenHead}
                  statusButtons={buttons}
                ></NewHome3>
              );
            } else if (errorStatus === "error") {
              return (
                <NewHomeError
                  backgroundColor={backgroundColor}
                  imagenHead={imagenHead}
                ></NewHomeError>
              );
            } else {
              return (
                <NewHome2
                  backgroundColor={backgroundColor}
                  imagenHead={imagenHead}
                  statusButtons={buttons}
                  socialButtons={social}
                ></NewHome2>
              );
            }
          })()}
        </React.Fragment>
      )}
    </div>
  );
};
