import React, { useState } from "react";
import "./RadioInputSelection.css";

// import styled from "styled-components";

const RadioInputSeleccion = ({
  tituloTopping,
  codigoCurrency,
  codigoPais,
  guardarToppingsSeleccionados,
  color_botton,
  color_hover,
}) => {
  // toppings_groups = tituloTopping
  const [toppingsSeleccionados, setToppingsSeleccionados] = useState();
  console.log();
  const [informacionItem, setInformacionItem] = useState();

  const [isBotonActive, setIsBotonActive] = useState(false);

  const actualizarValorSeleccionado = (
    indexExterno,
    indexInterno,
    isChecked
  ) => {
    // indexExterno: index de la categoria Ejem: Bebidas
    //indexInterno: index del toping seleccionado ejem: Coca cola
    // console.log("actualizarValorSeleccionado ");

    let categoriaSeleccionada = tituloTopping[indexExterno];
    let toppingSeleccionado = categoriaSeleccionada.toppings[indexInterno];

    if (toppingsSeleccionados !== undefined) {
      // console.log("toppingsSeleccionados ");
      //verifica si la categoria del topping seleccionado existe en el set

      let filtro = toppingsSeleccionados.filter(
        (categoria) => categoria.name === categoriaSeleccionada.name
      );

      let categoria = filtro[0];
      //Si no existe se crea el objeto y se agrega al set
      if (categoria === undefined) {
        const topingsXCategoria = {
          uuid: categoriaSeleccionada.uuid,
          name: categoriaSeleccionada.name,
          topingsSeleccionados: [toppingSeleccionado],
        };
        guardarToppingsSeleccionados([
          ...toppingsSeleccionados,
          topingsXCategoria,
        ]);
        // agrega un nuevo item a un array, se esta creando un nuevo array con la informacion anterior mas el item agregado.
        setToppingsSeleccionados([...toppingsSeleccionados, topingsXCategoria]);
      } else {
        // Si existe la categoria se agrega el nuevo toping al array de topings de la categoria
        //Actualizar la lista de topings seleccionados con los nuevos valores
        // console.log("categoriaSeleccionada " + categoriaSeleccionada );
        // Aqui entra y funciona como radio bottom
        if (
          categoriaSeleccionada.type === 0 &&
          categoriaSeleccionada.cant_max === 1
        ) {
          const newState = toppingsSeleccionados.map((obj) => {
            if (obj.name === categoriaSeleccionada.name) {
              return {
                uuid: categoriaSeleccionada.uuid,
                name: categoriaSeleccionada.name,
                topingsSeleccionados: [toppingSeleccionado],
              };
            }
            return obj;
          });
          guardarToppingsSeleccionados(newState);
          setToppingsSeleccionados(newState);
        } else {
          if (isChecked === true) {
            // si el topping esta seleccionado toca eliminarlo de la lista.
            const newState = toppingsSeleccionados.map((obj) => {
              if (obj.name === categoriaSeleccionada.name) {
                // este filtro crea un array con todos los tippings menos el que se esta seleccionando.
                var listaToppings = obj.topingsSeleccionados.filter((item) => {
                  return item.name !== toppingSeleccionado.name;
                });

                return {
                  uuid: categoriaSeleccionada.uuid,
                  name: categoriaSeleccionada.name,
                  topingsSeleccionados: listaToppings,
                };
              }
              return obj;
            });
            guardarToppingsSeleccionados(newState);
            setToppingsSeleccionados(newState);
          } else {
            const newState = toppingsSeleccionados.map((obj) => {
              if (obj.name === categoriaSeleccionada.name) {
                return {
                  uuid: categoriaSeleccionada.uuid,
                  name: categoriaSeleccionada.name,
                  topingsSeleccionados: [
                    ...obj.topingsSeleccionados,
                    toppingSeleccionado,
                  ],
                };
              }
              return obj;
            });
            guardarToppingsSeleccionados(newState);
            setToppingsSeleccionados(newState);
          }
        }
      }
    } else {
      const topingsXCategoria = {
        uuid: categoriaSeleccionada.uuid,
        name: categoriaSeleccionada.name,
        topingsSeleccionados: [toppingSeleccionado],
      };
      guardarToppingsSeleccionados([topingsXCategoria]);
      setToppingsSeleccionados([topingsXCategoria]);
    }
  };

  return (
    <>
      {tituloTopping.map((titulo, indexExterno) => {
        var categoriaGuardada;
        var desabilitarCheckbox = false;
        if (toppingsSeleccionados !== undefined) {
          let filtro = toppingsSeleccionados.filter(
            (categoria) => categoria.name === titulo.name
          );
          categoriaGuardada = filtro[0];
        }
        if (categoriaGuardada !== undefined) {
          if (
            categoriaGuardada.topingsSeleccionados.length === titulo.cant_max
          ) {
            desabilitarCheckbox = true;
          }
        }

        return (
          <React.Fragment key={titulo.uuid}>
            <div className="subtitulosOpciones">
              <h3 className="titulo-categoria">{titulo.name}</h3>
              <div className="contenedor-cantidades">
                <p className="cantidades">
                  Min: {titulo.cant_min} - Max: {titulo.cant_max}
                </p>
              </div>
            </div>

            {titulo.toppings?.map((opciones, indexInterno) => {
              return (
                <React.Fragment key={opciones.uuid}>
                  <div
                    className="fila-option-detalle-alternativo"
                    id="SelectToppings"
                  >
                    <label className="options-comida" htmlFor={opciones.e}>
                      {opciones.name} +
                      {opciones.price.toLocaleString(codigoPais, {
                        style: "currency",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        currency: codigoCurrency,
                      })}
                      {(() => {
                        if (titulo.type === 0 && titulo.cant_max === 1) {
                          return (
                            <React.Fragment key={titulo.uuid}>
                              <input
                                id={opciones.e}
                                type="radio"
                                name={titulo.uuid}
                                value={informacionItem}
                                onChange={(e) =>
                                  actualizarValorSeleccionado(
                                    indexExterno,
                                    indexInterno
                                  ) === informacionItem
                                }
                                // style={{accentColor: "yellow"}}
                                style={{
                                  accentColor: `${color_botton}`,
                                  border: "none",
                                  position: "relative",
                                }}
                              ></input>
                            </React.Fragment>
                          );
                        } else {
                          // console.log("esta es la cantidad Maxima: " + titulo.cant_max);

                          var isChecked = false;

                          if (categoriaGuardada !== undefined) {
                            // console.log(categoriaGuardada);
                            let lista = categoriaGuardada.topingsSeleccionados;
                            // console.log( "topingsSeleccionados" + lista)
                            let filtro = lista.filter(
                              (topping) => topping.name === opciones.name
                            );
                            if (filtro[0] !== undefined) {
                              isChecked = true;
                            }
                          }
                          let checkboxId = "checkbox-" + opciones.uuid;

                          return (
                            <input
                              id={checkboxId}
                              type="checkbox"
                              className="options-input"
                              name={titulo.name}
                              value={informacionItem}
                              checked={isChecked}
                              onChange={(e) =>
                                actualizarValorSeleccionado(
                                  indexExterno,
                                  indexInterno,
                                  isChecked
                                ) === informacionItem
                              }
                              disabled={isChecked ? false : desabilitarCheckbox}
                              // ? es if y : es el else.
                              style={{
                                accentColor: `${color_botton}`,
                                border: "none",
                              }}
                            ></input>
                          );
                        }
                      })()}
                    </label>
                  </div>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RadioInputSeleccion;
