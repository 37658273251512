import React, { useState } from "react";
import "./CarruselCategorias.css";
import imgLoader from "../Imagenes/Pedidos.jpg";

import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
export const CarrionCategoryLink = (props) => {
  const { openCategories, filtrarBusqueda, setBuscarPalabra } = props;
  const [isBotonActive, setIsBotonActive] = useState(false);
  const [indexCategoriaSeleccionada, setIndexCategoriaSeleccionada] =
    useState(0);
  const [subCategorias, setSubCategorias] = useState([]);
  const obtenerSubCategorias = async (uuid) => {
    var pleiztUuid = localStorage.getItem("pleiztUuid");
    const urlSubCategorias = `https://dev-api.menu.iguarayalabs.com/category/${uuid}`; // la url debe ser global
    const res = await fetch(urlSubCategorias, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": pleiztUuid, // este uuid debe ser dinamico.
        "branch-uuid":localStorage.getItem("idBranch"), // este uuid debe ser dinamico.
      }),
    }).then((res) => res.json());
    let infoSubCategoria = res.data.sub_cateogry; // envia toda la informacion del array
    setSubCategorias(infoSubCategoria);
  };
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <div className="CarruselCategorias">
      <Slider {...settings}>
        {openCategories?.map((item, i) => {
          return (
            <div className="card" key={item.uuid}>
              <Link
                to={"/menu/category"}
                style={{ textDecoration: "none" }}
                key={item.uuid}
              >
                <div
                  className="card-top"
                  onClick={() => {
                    obtenerSubCategorias(item.uuid);
                    setIsBotonActive(!isBotonActive);
                    setIndexCategoriaSeleccionada(i);
                  }}
                >
                  <img
                    src={item.photo}
                    alt="imagen"
                    className="imagenCategoria"
                  />
                  <div
                    style={{ textDecoration: "none" }}
                    className="new-name-categories"
                    $isBotonActive={i === indexCategoriaSeleccionada}
                    onClick={() => {
                      obtenerSubCategorias(item.uuid);
                      setIsBotonActive(!isBotonActive);
                      setIndexCategoriaSeleccionada(i);
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
