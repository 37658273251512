import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import IcoMenu from "../../Imagenes/ico_menu.svg";
import icoCcollect from "../../Imagenes/ico_ collect.svg";
import IcoBookings from "../../Imagenes/ico_bookings.svg";
import IcoBranch from "../../Imagenes/ico_branch.svg";
import IcoDelivery from "../../Imagenes/ico_delivery.svg";

export const NewHomeError = ({backgroundColor,imagenHead}) => {
  let navigate = useNavigate();
  localStorage.setItem("mostrarPopUp", true);
  const onLinkClick = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 1);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioMesa");
  };
  const OnDelovery = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 0);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioDomicilio");
  };
  const OnMenu = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 3);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioMesa");
  };

  return (
    <React.Fragment>
      <div
        className="container-new-principal-home"
        style={{
          background: backgroundColor,
        }}
      >
        <div className="container-imagen-cabecera-alternativo">
          <img
            src={imagenHead}
            alt="imagenHead"
            className="head-new-home"
          ></img>
        </div>
        <section className="container-options-home">
          <button className="button-new-home">
            <p className="title-metodo-pago">
              <img
                src={IcoMenu}
                alt="imagenCabecera"
                className="button-img-new-home"
              ></img>
            </p>
            <p className="button-title-new-home">Restaurante no habilitado </p>
          </button>          
        </section>
      </div>
    </React.Fragment>
  );
};
