import "../../App.css";
import "./Header.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import iconsCart from "../../Imagenes/icons_cart.svg";
import iconsLeft from "../../Imagenes/arrow_left.svg";
import icoSearch from "../../Imagenes/ico_buscar.svg";

export const Header = (props) => {
  const { propiedadesCompany } = props;
  const [listaProductosCarrito, setListaProductosCarrito] = useState();
  var totalCantidadCarrito = 0;
  useEffect(() => {
    const informacionLocalStorage = localStorage.getItem(
      "infoProductoSeleccionado"
    );
    if (
      informacionLocalStorage !== undefined &&
      informacionLocalStorage !== null
    ) {
      let parsedRespuesta = JSON.parse(informacionLocalStorage); // JSON.parse convierte la información de string a un objeto de javascript.

      setListaProductosCarrito(parsedRespuesta);
    }
  }, []);
  if (listaProductosCarrito !== undefined) {
    listaProductosCarrito.map((item) => {
      totalCantidadCarrito += item.cantidadProducto;
    });
  }
  return (
    <div>
      <div className="header">
        <div className="left">
          <Link to="/">
            <img
              src={iconsLeft}
              alt="Logo de la aplicación"
              className="header-arrow-left"
            />
          </Link>
        </div>
        <div className="center">
          <img
            src={propiedadesCompany.company_logo}
            className="header-company-logo"
            alt="logo"
          />
        </div>
        <div className="right">
          {(() => {
            var typeSend = localStorage?.getItem("typeSend");
            if (typeSend < 2) {
              if (totalCantidadCarrito > 0) {
                return (
                  <div>
                    <div className="header-quantity-cart">
                      <div className="header-quantity-cart-1">
                        {totalCantidadCarrito}
                      </div>
                    </div>
                    <Link to="/shoppingCart">
                      <div className="cart">
                        <img
                          src={iconsCart}
                          className="header-boton-cart"
                          alt="cart"
                        />
                      </div>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <Link to="/shoppingCart">
                    <div className="cart">
                      <img
                        src={iconsCart}
                        className="header-boton-cart"
                        alt="cart"
                      />
                    </div>
                  </Link>
                );
              }
            }
          })()}
        </div>
      </div>
      {/* <div className="search-input-container">
        <input className="search-input" type="text" placeholder="Buscar plato" />
        <div className="search-icon">
          <img src={icoSearch} alt="search-icon" />
        </div>
      </div>
        */}
    </div>
  );
};

export default Header;
