import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import "./ListaTiendas.css";

import logoGris from "../Imagenes/Btn-Black.svg";
import flechaDerecha from "../Imagenes/Ico_next.svg";
import imgPerfil from "../Imagenes/Avatar03.svg";
import imgTienda from "../Imagenes/Img_tienda01.png";
import IcoAtras from "../Imagenes/Ico_back.svg";
import MenuDesplegable from '../MenuDesplegable/MenuDesplegable';
import { FooterHome } from '../FooterHome/FooterHome';


const ListaTiendas = ({propiedadesCompany}) => {
    var pleiztUuid =  localStorage.getItem("pleiztUuid");


    const [listaTiendas, setListaTiendas] = useState();


    const obtenerListaTiendas = async () => {
        const urlListaTiendas = `https://dev-api.menu.iguarayalabs.com/shops/`;


        var pleiztUuid =  localStorage.getItem("pleiztUuid");

        const respuesta = await fetch(urlListaTiendas, {
            method: "get",
            headers: new Headers({
                'pleizt-uuid': pleiztUuid,
            })
        })
        .then((respuesta) => respuesta.json() );

        let infoListaTiendas = respuesta.data;
        setListaTiendas(infoListaTiendas);
    }

    useEffect(() => {
        obtenerListaTiendas()
    }, [])
    

    
    return (


        <>
            <div className='container-principal-carrito'>
                <div className='container-lista-tiendas '>

                    <div style={{marginLeft: "20px", marginRight: "20px" }} >
                        <MenuDesplegable propiedadesCompany={propiedadesCompany} />
                    </div>
        
                    {/* <div className='head-carrito'>
                    <Link to={'/' + pleiztUuid} >
                        <img src={logoGris} alt='logoGris' style={{ width: "95px", height: "52px", marginTop: "10px", display: "flex", flexDirection: "row", justifyContent: "flex-start" }} ></img>
                    </Link>
                        <div className='pedido'>
                            <img className='imagen-perfil' src={imgPerfil} alt='IcoLocacion'></img>
                        </div>
                    </div> */}

                

                    <div className='container-boton-borrar' style={{ marginTop: " 20px ", marginBottom: "30px" }}>
                        <div className='container-info-entrega'>
                            <h3 className='titulos-h3' style={{margin:"0px", padding:"0px"}} >Restaurantes</h3>    
                            <p className='informacion-list' style={{margin:"0px", padding:"0px"}} >Restaurantes mas cercanos</p>   
                        </div>
                    </div>


                    {(() => { 
                        if(listaTiendas !== undefined){
                            return(
                                listaTiendas.map((tienda) => {
                                    return( 
                                        <>
                                            <div key={tienda.id} className='lista-carrito' >
                                                <div className='container-foto-plato'>
                                                    <img 
                                                    key={tienda.id} src={logoGris} 
                                                    alt='fotoTienda' style={{ width: "72px", height: "50px", borderRadius: "12px", objectFit: "cover", background: "gray" }} ></img>
                                                </div>
                                                <div className='container-informacion-list'>
                                                    <h4 className='informacion' style={{margin:"0px", padding:"0px"}} > {tienda.name} </h4> 
                                                    <p className='informacion-list' style={{margin:"0px", padding:"0px"}} > {tienda.address} </p>
                                                </div>
                                                <div className='fin'>
                                                    <img className="flecha-derecha-fondo" src={flechaDerecha}></img>
                                                </div>
                                            </div>
                                            <hr />
                                        
                                        </>
                                    )
                                })
                            )
                        }
                    })()}

                {/* <div className='container--footer-home' > 
                    <FooterHome />
                </div> */}
                
                </div>  
            </div> 
        
        </>
    )
}

export default ListaTiendas