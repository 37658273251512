import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppMenuDigital from './AppMenuDigital';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter >
  {/* // <React.StrictMode> */}
      <AppMenuDigital />
  {/* // </React.StrictMode> */}
  </BrowserRouter>
);

reportWebVitals();
