import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import logoGris from "../Imagenes/Btn-Black.svg";

import "./FormularioMesa.css";

const FormularioMesa = () => {
  const [idCiudadSucursal, setIdCiudadSucursal] = useState();
  const [nameCiudadSucursal, setNameCiudadSucursal] = useState(
    "Selecciona una ciudad"
  );
  const [idTienda, setIdTienda] = useState();
  const [nameTienda, setNameTienda] = useState("Selecciona una tienda");
  const [error, setError] = useState(false);

  const [registros, setRegistros] = useState([]);

  const [listaCiudades, setListaCiudades] = useState([]);
  const [propiedadesCompanys, setPropiedadesCompany] = useState([]);
  const [listaTiendas, setListaTiendas] = useState([]);
  useEffect(() => {
    var typeSend = localStorage?.getItem("typeSend");
    if (typeSend === null) {
      localStorage.setItem("typeSend", 1);
    }

    obtenerCiudades();
  }, []);
  const obtenerTiendasXCiudad = async (idCiudad) => {
    var idCiudad = localStorage.getItem("idCiudadSucursal");
    // console.log(idCiudad);
    if (idCiudad !== undefined || idCiudad !== null) {
      const urlTiendas = `https://dev-api.menu.iguarayalabs.com/shops/${idCiudad}`;
      const respuesta = await fetch(urlTiendas, {
        method: "get",
        headers: new Headers({
          "pleizt-uuid": window.location.hostname.split(".")[0],
        }),
      }).then((respuesta) => respuesta.json());
      let infoTiendas = respuesta.data;
      setListaTiendas(respuesta.data);
    }
  };
  const obtenerCiudades = async () => {
    const urlCiudades = `https://dev-api.menu.iguarayalabs.com/cities`;
    const respuesta = await fetch(urlCiudades, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": window.location.hostname.split(".")[0],
      }),
    }).then((respuesta) => respuesta.json());
    setPropiedadesCompany(respuesta.properties);
    let infoCiudades = respuesta.data;
    setListaCiudades(infoCiudades);
  };

  const enviarIdCiudad = (idCiudadSucursal, nameCiudadSucursal) => {
    localStorage.setItem("idCiudadSucursal", idCiudadSucursal);
    obtenerTiendasXCiudad(idCiudadSucursal);
    setIdCiudadSucursal(idCiudadSucursal);
    setNameCiudadSucursal(nameCiudadSucursal);
  };

  const enviarIdTienda = (nameTienda) => {
    localStorage.setItem("idBranch", nameTienda.value);
    localStorage.setItem("uuidShop", nameTienda.value);
    localStorage.setItem("nameTienda", nameTienda.label);
    localStorage.setItem("Direccion", "Tienda: " + nameTienda.label);
    localStorage.setItem("address", nameTienda.label);
    obtenerTiendasXCiudad(nameTienda.label);

    setNameTienda(nameTienda.label);
    setIdTienda();
  };

  const guardarTipoEnvio = (id) => {
    localStorage.setItem("idTipoEnvio", id);
  };

  let navigate = useNavigate();
  const guardarInfo = () => {
    if (
      nameCiudadSucursal === undefined ||
      nameTienda === "Selecciona una tienda"
    ) {
      alert("Por favor ingresar todos los datos");
    } else {
      var infoForm = { nameTienda, nameCiudadSucursal };
      setRegistros([...registros, infoForm]);

      // Guarda info en el localStorage
      localStorage.setItem("nameCiudadSucursal", nameCiudadSucursal);
      localStorage.setItem("nameTienda", nameTienda);

      // enviarInfoOrdenMesa();
      // setPopUpEntrega(!popUpEntrega);
      navigate("/menu");
      guardarTipoEnvio("1");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    try {
      const localStorageAnswers = localStorage.setItem("registros");

      let parsedRespuesta;

      if (!localStorageAnswers) {
        localStorage.setItem("registros", JSON.stringify(registros)); // envia info en forma de string.

        parsedRespuesta = registros;
      } else {
        parsedRespuesta = JSON.parse(localStorageAnswers.getItem("registros")); // JSON.parse convierte la información de string a un objeto de javascript.
      }
      setRegistros(parsedRespuesta);
    } catch (error) {
      setError(error);
    }
  }, []);

  return (
    <>
      <div className="containerPrincipal">
        <div className="head-formulario">
          <Link to={"/"}>
            <img
              src={logoGris}
              alt="logoGris"
              style={{
                width: "95px",
                height: "52px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            ></img>
          </Link>
        </div>

        <form className="form" onSubmit={onSubmit}>
          <img
            src={propiedadesCompanys.company_logo}
            alt="logoCompany"
            style={{ zIndex: "1", width: "150px", top: "190px" }}
          ></img>

          {(() => {
            if (localStorage.getItem("idTipoEnvio") === 1) {
              return <h3>Recoger en tienda</h3>;
            }
          })()}

          {(() => {
            if (listaCiudades !== undefined) {
              return (
                <Select
                  id="sucursales"
                  className="options"
                  defaultValue={{
                    label: nameCiudadSucursal,
                    value: idCiudadSucursal,
                  }}
                  // options={  optionsCiudades.map(city => ({ label: city.nameCity, value: city.id }))  }
                  options={listaCiudades.map((ciudad) => ({
                    label: `${ciudad.name}`,
                    value: `${ciudad.id}`,
                  }))}
                  // options={  { label: ciudad.name, value: ciudad.id }  }
                  // options={ opcionesCiudad }

                  onChange={(e) => {
                    enviarIdCiudad(e.value);
                    setNameCiudadSucursal(e.label);
                  }}
                  // onChange={enviarIdCiudad()}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 12,
                    width: 280,
                    /*
                    colors: {
                      ...theme.colors,
                      // primary25: colorHover,
                      primary: colorBoton,
                      primary50: colorBoton,
                    },*/
                  })}
                />
              );
            }
          })()}

          {(() => {
            if (listaTiendas !== undefined) {
              if (idCiudadSucursal !== undefined) {
                return (
                  <Select
                    id="Tiendas"
                    className="options"
                    defaultValue={{ label: nameTienda, value: idTienda }}
                    options={listaTiendas.map((tienda) => ({
                      label: `${tienda.name}`,
                      value: `${tienda.uuid}`,
                    }))}
                    // options={ opcionesTiendas }
                    // onChange={(e) => { setIdTienda(e.value); setNameTienda(e.label) } }

                    onChange={(e) => {
                      enviarIdTienda(e);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 12,
                      width: 280,
                    })}
                  />
                );
              }
            }
          })()}

          <button
            className="boton"
            onClick={guardarInfo}
            style={{
              margin: "40px",
              border: "none",
              padding: "9px",
              cursor: "pointer",
              width: "272px",
              borderRadius: "12px",
              background: "#28304A",
              height: "50px",
              fontSize: "14px",
              fontWeight: "700",
              color: "#ffffff",
            }}
          >
            Guardar
          </button>
        </form>
      </div>
    </>
  );
};

export default FormularioMesa;
