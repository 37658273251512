import React, { useState } from "react";
import "./CarruselCategorias.css";
import imgLoader from "../Imagenes/Pedidos.jpg";
import { Tags } from "../../componentes/CarruselCategorias/Tags";
import Slider from "react-slick";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const CarrionGroup = (props) => {
  const { openCategories, products, title } = props;
  const codigoPais = "es-CO";
  const codigoCurrency = "COP";
  const formatter = new Intl.NumberFormat(codigoPais, {
    style: "currency",
    currency: codigoCurrency, // Puedes cambiar a 'USD', 'GBP', etc. según la moneda que necesites
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  // settings es el responsive del carrusel o scroll horizontal.
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <div className="CarruselCategorias">
      <div className="carrion-label">{title}</div>
      <Slider {...settings}>
        {openCategories.map((item, i) => {
          return (
            <Link
              to={"/detail/" + item.uuid}
              style={{ textDecoration: "none" }}
              key={item.uuid}
            >
              <div className="carrion-card" key={item.uuid}>
                <div className="carrion-card-top">
                  <img
                    src={item.photo}
                    alt="imagen"
                    className="imagenCategoria"
                  />
                  {(() => {
                    if (item.tags !== undefined) {
                      return (
                        <Tags tag={item.tags} className={"carrion-tag"}></Tags>
                      );
                    }
                  })()}
                  {/* <div
                    className="carrion-discount"
                    style={{
                      background: "#FF007E",
                    }}
                  >
                    20 %
                  </div>*/}

                  <div className="carrion-price">
                    {formatter.format(item.price)}
                  </div>
                </div>
                <div className="carrion-informacion">{item.name}</div>
              </div>
            </Link>
          );
        })}
      </Slider>
    </div>
  );
};
