import { useEffect, useState } from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import Popup from "../Popup/Popup";
import { CarrionCategory3 } from "../CarruselCategorias/CarrionCategory3";

import Banner from "../Carrucel/BannerPrueba";
import { Header3 } from "../header/Header3";

import { MenuListAlternativo3 } from "../../componentes/MenuListAlternativoHome/MenuListAlternativo3";
export const Menu3 = () => {
    let navigate = useNavigate();
    const [openCategories, setOpenCategories] = useState([]);
    const [openPopUp, setOpenPopUp] = useState({});
    const [propiedadesCompany, setPropiedadesCompany] = useState([]);
    const [nConfig, setNconfig] = useState({});
    const { type_home, color_hover, color_botton, table_name } =
        propiedadesCompany;
    // aqui se guarda el color del boton azul
    localStorage.setItem("colorBoton", color_botton);
    localStorage.setItem("colorHover", color_hover);

    const pleiztUuids = window.location.hostname.split(".")[0];

    var pleiztUuid;
    if (pleiztUuids === undefined) {
        if (localStorage.getItem("pleiztUuid") === undefined) {
        } else {
            pleiztUuid = localStorage.getItem("pleiztUuid");
        }
    } else {
        pleiztUuid = pleiztUuids;
        if (localStorage.getItem("pleiztUuid") !== pleiztUuid) {
            localStorage.clear();
        }
        localStorage.setItem("pleiztUuid", pleiztUuid);
    }
    const [orderActive, setOrderActive] = useState([]);
    // primer end point o servicio
    const urlHome = `https://dev-api.menu.iguarayalabs.com/home-new-3`;
    const codigoPais = "es-CO";
    const codigoCurrency = "COP";
    //const [orderActive, setOrderActive] = useState([]);
    useEffect(() => {
        var pleiztService = localStorage.getItem("pleiztService");
        (async function () {
            const res = await fetch(urlHome, {
                method: "get",
                headers: new Headers({
                    "pleizt-uuid": pleiztUuid, // y se reemplaza la informacion
                    "pleizt-service": pleiztService,
                    "branch-uuid": localStorage.getItem("idBranch"),
                }),
            }).then((res) => res.json());
            var mensajeExito = res.success;
            if (mensajeExito === "success") {
                setOpenCategories(res.data.categories); // aqui estoy pasando el objeto categories, no es necesario pasarlo en el map.
                setOpenPopUp(res.data.popup);
                setPropiedadesCompany(res.properties);
                setOrderActive(res.data.order);
                setNconfig(res.data.config)

            } else {
                console.log("No hay menus para este restaurante");
            }
        })();
        var typeSend = localStorage?.getItem("typeSend");
        if (typeSend === "1") {
            var idBranch = localStorage?.getItem("idBranch");
            if (idBranch === null) {
                navigate("/FormularioMesa");
            }
        } else if (typeSend === "0") {
            var isAddress = localStorage?.getItem("isAddress");
            if (isAddress === null) {
                //      console.log("FormularioDomicilio")
                navigate("/FormularioDomicilio");
            }
        } else if (typeSend === "3") {
            navigate("/menu");
        } else {
            navigate("/home");
        }

    }, [urlHome]);

    return (
        <div className="App" >
            <header className="App-header" >
                <Header3 propiedadesCompany={propiedadesCompany} configHeader={nConfig} />

            </header>

            <CarrionCategory3
                openCategories={openCategories}
                type_home={type_home}
            />
            {openCategories?.map((item, i) => {
                return (
                    <section id={item.uuid} className="menu-3-section">
                        <h2>{item.name}</h2>
                        <MenuListAlternativo3
                            categoriaSeleccionada={item.items}
                            codigoCurrency={codigoCurrency}
                            codigoPais={codigoPais}
                            productoFiltrado={null}
                            color_botton={color_botton}
                            color_hover={color_hover}
                        />
                    </section>
                )
            })}
        </div>
    );
};
export default Menu3;
