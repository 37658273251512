import React, { useState } from 'react';
import styled from 'styled-components';

const Popup = ({imagenPopUp}) => {

    const [popupState, setPopupState] = useState(true);
    // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.

    const cerrarPopUp = () => {
        crearTimeOut();
        setPopupState(false);
    }
    
    const crearTimeOut = () => {
        localStorage.setItem("mostrarPopUp", "false");
        const timer =  setTimeout(() => {
            localStorage.setItem("mostrarPopUp", "true");
            setPopupState(true);
        }, 600000) //  3600000 = a 1 hora // 7200000 = a 2horas // 18000000 = a 5 horas. // ejemplo rapido 1 minuto - 60000
        return () => clearTimeout(timer); // se limpia para que no se ejecute varias veces
    }

    return (
        <>
            {(() => {
                var mostrarPopUp = localStorage.getItem("mostrarPopUp"); 
                // console.log(mostrarPopUp);
                if(mostrarPopUp === null || mostrarPopUp === "true" ){
                    return(
                
                        <Overlay className="Overlay" onClick={() => cerrarPopUp()} > {/* fondo oscuro */}
                            <ContenedorModal>
                                <Contenido className='Contenido'>
                                    <>
                                    <img src={imagenPopUp} alt='img popup'/>
                                    </>
                                </Contenido>

                                <BotonCerrar className='BotonCerrar' onClick={() => cerrarPopUp()} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </BotonCerrar>
                            </ContenedorModal>
                        </Overlay>
                    )
                }
            })()}    
        </>
    )
}

////// hacia bajo son los estilos del popup

    const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute; 

    
    img {
        width: 100%;
        vertical-align: top;
        border-radius: 5px;
    }
    `;

    const Overlay = styled.div `
        width: 100vw; // tamaño de la pantalla
        height: 115vh; // tamaño de la pantalla
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    const ContenedorModal = styled.div`
        
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.3); /* Fondo semitransparente */
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `;

    const BotonCerrar = styled.div`
        position: absolute;
        top: 15px;
        right: 20px; 
        
        width: 30px;
        height: 30px;
        border: none;
        background: none;
        cursor: pointer;
        transition: .3s ease all;
        border-radius: 5px;
        color: #1766DC;

        &:hover {
            background:#f2f2f2;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    `;

export default Popup;


