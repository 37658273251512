import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";

import imgDomicilio from "../Imagenes/entrega-de-comida.png"
import imgRecogerTienda from "../Imagenes/comercio.png"

const PopUpEntrega = props => {
    
    const { popUpEntrega, setPopUpEntrega, color_botton, color_hover, company_logo }  = props;    

    const guardarnombreTipoEnvio = (name) => {
        localStorage.setItem("TipoEntrega", name);
    }

    
    const tipoEnvio = localStorage.getItem("idTipoEnvio");
    if(tipoEnvio >= 1) {
        setPopUpEntrega(false);
    }
    

    const Boton = styled.button`
        border: none;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        color: white;
        fontSize: 10px;
        // background: blue
        width: 100px;
        
        background: ${ props => props.$isBotonActive ? `${color_hover}` : `${color_botton}` };
    `;


// background: ${  colorBoton  }

    // {popUpEntrega && } si el estado es true entonces va a mostrar el contenido a continuacion

    return (
        <>

            { popUpEntrega && 

            <Overlay className="Overlay" > {/* fondo oscuro */}
                <ContenedorModal>
                    
                    <img src={company_logo} alt='company_logo' style={{ zIndex: "1", width:"30px", top: "190px", borderRadius: "5px", marginLeft: "30px", marginTop: "30px" }} />
                    
                    <ContenidoEnvio className='Contenido'>
                        <>

                            <ContenedorImgBtn>
                                <img src={imgDomicilio} alt='img-domicilio' style={{ zIndex: "1", width:"80px", top: "190px", borderRadius: "5px" }}/>
                                <Link to={'/FormularioDomicilio' } style={{ textDecoration:"none" }}  >  
                                    <Boton  
                                        id='1'
                                        name='Domicilio'
                                        onClick={(e) => { guardarnombreTipoEnvio('Domicilio') }}
                                    ><p className='nombre-subcategoria'>Domicilio</p>
                                    </ Boton >
                                </Link>
                            </ContenedorImgBtn>
                       
                        
                            {/*<ContenedorImgBtn>
                                <img src={imgRecogerTienda} alt='img-recoger' style={{ zIndex: "1", width:"80px", top: "190px", borderRadius: "5px" }} />
                                
                                <Link to={'/FormularioMesa' } style={{ textDecoration:"none" }} >   
                                    <Boton  
                                        id='2'
                                        name='Recoger en tienda'
                                        onClick={(e) => { guardarnombreTipoEnvio('Recoger en tienda') }}
                                    >
                                        <p className='nombre-subcategoria'>Recoger</p>
                                    </ Boton >
                                </Link>
                            </ContenedorImgBtn>*/
}

                        </>
                    </ContenidoEnvio>

                    
                    {/* <BotonCerrar className='BotonCerrar' onClick={() => setPopUpEntrega(false)}
                    > 
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>    
                    </BotonCerrar> */}
                </ContenedorModal>
            </Overlay>

            }
        </>
    )
}



const ContenidoEnvio = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // position: absolute; 
    // padding: 20px;
    margin: 30px;
    // margin-top: 40px;

        @media (min-width:0px) and (max-width:300px) {
            margin: 10px;
        } 
    `;

const ContenedorImgBtn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // position: absolute; 
    // padding: 20px;

        @media (min-width:0px) and (max-width:300px) {
            margin: 10px;
        } 
    `;

    const Overlay = styled.div `
        width: 100vw; // tamaño de la pantalla 
        height: 115vh; // tamaño de la pantalla 
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    `;


    const ContenedorModal = styled.div`
        position: fixed;
        transform: translateX(-50%);
        transform: translateY(-15%);
        width: 435px;
        min-height: 100px;
        background: #fff;
        border-radius: 7px;


        // display: flex;
        // justify-content: center;
        // width: 430px;
        // height: 600px;
        // min-height: 100px;
        // background: #fff;
        // z-index: 1;
        // border-radius: 7px;
        // box-shadow: rgba(100,100, 111, 0.2) 0px 7px 29px 0px;
        // position: absolute;
        // transform: translateY(-10%);


        
        
        // tamaño de la imagen pop Productos o promociones de la empresa. 
        @media (min-width:0px) and (max-width:300px) {
            width: 230px;
            // height: 440px;
        }

        @media (min-width:301px) and (max-width:400px) {
            width: 290px;
            // height: 500px;
        }

        @media (min-width:401px) and (max-width:500px) {
            width: 340px;
            // height: 560px;
        }

        @media (min-width:500px) and (max-width:600px) {
            width: 320px;
            // height: 480px;
        }

        @media (min-width:601px) and (max-width:830px) {
            width: 430px;
            // height: 660px;
        }
    `;

    const BotonCerrar = styled.div`
        position: absolute;
        top: 23px;
        right: 20px; 
        
        width: 30px;
        height: 30px;
        border: none;
        background: none;
        cursor: pointer;
        transition: .3s ease all;
        border-radius: 5px;
        color: #1766DC;

        &:hover {
            background:#f2f2f2;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    `;

    // const colorBoton = localStorage.getItem("colorBoton");

    // const Boton = styled.button`
    //     border: none;
    //     border-radius: 5px;
    //     padding: 10px;
    //     cursor: pointer;
    //     color: white;
    //     fontSize: 10px;
    //     // background: blue
    //     width: 100px;
    //     background: ${  colorBoton  }
    // `;

    const Titulo = styled.p`
        border: none;
        border-radius: 5px;
        // padding: 10px;
        color: #4D4D4D;
        fontSize: 10px;
        margin: 30px;
        fontWeight: 400;
        // background: blue
        // --black-100, #4D4D4D
    `;

    
export default PopUpEntrega;