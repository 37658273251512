import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import barritaCerrar from "../Imagenes/Barrita-close.svg";
import icoReembolso from "../Imagenes/Ico_reembolso.svg";



const PopUpConCuantoPagas = props => {
    
    const { numeroPedidoFinal,  popUpEntrega, setPopUpEntrega, setValorInput, valorInput, codigoPais, codigoCurrency, propiedadesCompany }  = props;

    
    var pleiztUuid =  localStorage.getItem("pleiztUuid");

    const [popupState, setPopupState] = useState(true);
    // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.
    

    // console.log("valorInput del PopUp");
    // console.log(typeof valorInput);

    // const colorBoton = localStorage.getItem("colorBoton");

    var valorEnPesos = parseInt(valorInput);

    const enviarInformacion = (e) => {
        // console.log("enviarValorCuentoPaga");
        e.preventDefault()
        setValorInput(valorEnPesos);
        setPopUpEntrega(false);
        
    }

    const infoInputOnChange = (e) => {
        var value = e.target.value;
        if(value === "") {
            setValorInput(0);
        } else {
            // console.log("Funciona bien");
            setValorInput(value);
        }
    }


    const BotonGuardar = styled.button`
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-top: 12px;
    cursor: pointer;
    color: white;
    fontSize: 10px;
    width: 100%;
    background: ${ props => props.$isBotonActive ? `` : `${propiedadesCompany.color_botton}` };
    
    `;


    return (
        <>
            {popUpEntrega && 

            <Overlay className="Overlay" > {/* fondo oscuro */}
                <ContenedorModal>
                    <ContenedorTexto>

                        <div className='container-agregar-tarjeta'>
                            <img src={icoReembolso} alt='icoReembolso' style={{ width: "35px" }}></img>
                           {/*  <p className='subtitulos'>¿Con cuanto pagas?</p>    */}
                            <div>
                                <Titulo>¿Con cuanto pagas?</Titulo>                                
                            </div>
                            
                        </div>
                        

                        <ValorAPagar>
                            <input type="number" placeholder="$ 0" min={1}                           
                            // onChange= {(e) => setValorInput(e.target.value)} 
                            onChange= { infoInputOnChange } 
                            
                            style={{
                                background: "#F3F2F4",
                                color: "#4D4D4D",
                                fontSize: "20px",
                                fontWeight: "700",
                                lineHeight: "16px",
                                border: "none",
                                border: "#4D4D4D",
                                // width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "center",
                            }}></input>
                        </ValorAPagar>
                        
                        <BotonGuardar  
                            // isBotonActive={ i === indexCategoriaSeleccionada }
                            id='botonPedido'
                            onClick={(e) => enviarInformacion(e) } 
                        >
                        Aceptar</ BotonGuardar >
                            
                    </ContenedorTexto>

                    <BotonCerrar className='BotonCerrar' onClick={() => setPopUpEntrega(false)} > 
                        <img src={barritaCerrar} alt='logoGris' ></img>
                    </BotonCerrar>
                </ContenedorModal>
            </Overlay>
            }
        </>
    )    
}


    // const Overlay = styled.div `
    //     width: 100vw;
    //     height: 115vh;
    //     // position: fixed;
    //     top: 0;
    //     left: 0;
    //     background: rgba(0,0,0,.5);
    //     position: absolute; 
    //     z-index: 1;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     position: absolute; 
    // `;

    // const ContenedorModal = styled.div`
    //     // display: flex;
    //     // justify-content: center;
    //     // width: 430px;
    //     // height: 600px;
    //     // min-height: 100px;
    //     // background: #fff;
    //     position: relative; 
    //     // z-index: 1;
    //     // border-radius: 7px;
    //     // box-shadow: rgba(100,100, 111, 0.2) 0px 7px 29px 0px;
    //     // position: absolute;

    //     // position: fixed;
    //     // transform: translateX(-50%);
    //     // transform: translateY(-10%);
    //     // transform: translateY(45%);

    //     position: relative;
    //     height: 300px;
    //     width: 100%;
    //     min-height: 100px;
    //     margin-top: auto;
    //     background: #fff;
    //     border-radius: 25px 25px 0px 0px;

    //     // @media (min-width:0px) and (max-width:300px) {
    //     //     width: 100%;
    //     //     // width: 230px;
    //     //     // height: 440px;
    //     // }

    //     // @media (min-width:301px) and (max-width:400px) {
    //     //     // width: 290px;
    //     //     // height: 500px;
    //     // }

    //     // @media (min-width:401px) and (max-width:500px) {
    //     //     width: 340px;
    //     //     // height: 560px;
    //     // }

    //     // @media (min-width:500px) and (max-width:600px) {
    //     //     width: 320px;
    //     //     // height: 480px;
    //     // }

    //     // @media (min-width:601px) and (max-width:830px) {
    //     //     width: 430px;
    //     //     // height: 660px;
    //     // }
    // `;



/// cajita blanca con el contenido
const ContenedorModal = styled.div`
    position: relative;
    width: 100vw;
    // /* transform: translateX(-50%); */
    // /* transform: translateY(75%); */
    height: 300px;
    width: 100%;
    min-height: 100px;
    background: #fff;
    margin-top: auto;
    border-radius: 25px 25px 0px 0px;
`;




//// overlay
const Overlay = styled.div `
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

    const BotonCerrar = styled.div`
        position: absolute;
        top: 10px;
        right: 50%; 
        
        width: 30px;
        height: 30px;
        border: none;
        background: none;
        cursor: pointer;
        transition: .3s ease all;
        border-radius: 5px;
        color: #1766DC;

        &:hover {
            background:#f2f2f2;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    `;

   


    const ContenedorTexto = styled.div`
    border: none;
    border-radius: 5px;
    // padding: 10px;
    color: #4D4D4D;
    fontSize: 10px;
    margin: 38px;
    margin-top: 60px;
    fontWeight: 400;
    // background: blue
    // --black-100, #4D4D4D
`;

    const Titulo = styled.p`
        
        color: #4D4D4D;
        font-size: 15px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
    `;


    const SubTitulo = styled.p`
        color: #4D4D4D;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
    `;
    
    // const [valorInput, setValorInput] = useState();
    
    // .input.attrs({
    //     type: "number",
    //     placeholder: "$10",
    //     // value: valorInput,
    //     // onChange: {() => setValorInput()}
    // })`
    const ValorAPagar = styled.div `
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        background: #F3F2F4;
        color: #4D4D4D;
        font-size: 20px;
        font-weight: 700;
        lineHeight: 16px;
        height: 72px;
        border: none;
        border-radius: 12px 12px 12px 12px;
        margin-top: 20px;
        
        padding: 15px;

    `;


const colorBoton = localStorage.getItem("colorBoton");
const BotonGuardar = styled.button`
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-top: 12px;
    cursor: pointer;
    color: white;
    fontSize: 10px;
    width: 100%;
    background: ${  colorBoton  }
`;

export default PopUpConCuantoPagas;