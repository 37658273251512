import { useEffect, useState } from "react";
import "../../App.css";
import {
  useNavigate,
} from "react-router-dom";

import { CarruselCategorias } from "../../componentes/CarruselCategorias/CarruselCategorias";
import { MenuGrillaAlternativo } from "../../componentes/DiseñoAlternativoHome/MenuGrillaAlternativo";
import { MenuListAlternativo } from "../../componentes/MenuListAlternativoHome/MenuListAlternativo";
import { Header } from "../../componentes/header/Header";
export const MenuCategory = () => {
  let navigate = useNavigate();
  const [openCategories, setOpenCategories] = useState([]);

  const [subCategorias, setSubCategorias] = useState([]);

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState();
  // const {uuid, name, price, photo, description} = categoriaSeleccionada;
  const [propiedadesCompany, setPropiedadesCompany] = useState([]);

  const {
    type_home,
    color_hover,
    color_botton,
  } = propiedadesCompany;

  // set contiene productos filtrados por el buscador
  const [productoFiltrado, setProductoFiltrado] = useState();
  // aqui se guarda el color del boton azul
  localStorage.setItem("colorBoton", color_botton);
  localStorage.setItem("colorHover", color_hover);

  const pleiztUuids = window.location.hostname.split(".")[0];

  var pleiztUuid;
  if (pleiztUuids === undefined) {
    if (localStorage.getItem("pleiztUuid") === undefined) {
    } else {
      pleiztUuid = localStorage.getItem("pleiztUuid");
    }
  } else {
    pleiztUuid = pleiztUuids;
    if (localStorage.getItem("pleiztUuid") != pleiztUuid) {
      localStorage.clear();
    }
    localStorage.setItem("pleiztUuid", pleiztUuid);
  }
  // primer end point o servicio
  const urlHome = `https://dev-api.menu.iguarayalabs.com/home/`;

  useEffect(() => {
    // var pleiztUuid =  localStorage.getItem("pleiztUuid");
    var idUsuarioLogeado = localStorage.getItem("idUsuarioLogeado");

    if (pleiztUuid !== null) {
      (async function () {
        const res = await fetch(urlHome, {
          method: "get",
          headers: new Headers({
            "pleizt-uuid": pleiztUuid, // y se reemplaza la informacion
            "pleizt-user": idUsuarioLogeado,
            "branch-uuid": localStorage.getItem("idBranch"),
          }),
        }).then((res) => res.json());
        var mensajeExito = res.success;
        if (mensajeExito === "success") {
          setOpenCategories(res.data.categories); // aqui estoy pasando el objeto categories, no es necesario pasarlo en el map.
        //  setOpenBanners(res.data.banners);
         // setOpenPopUp(res.data.popup);
          setPropiedadesCompany(res.properties);
          // aqui muestro la primera subcategoria o lista de productos por defecto o por primera vez.
          let primeraCategoria = res.data.categories[0];
          obtenerSubCategorias(primeraCategoria.uuid);

        } else {
          console.log("No hay menus para este restaurante");
        }
      })();
      var typeSend = localStorage?.getItem("typeSend");
      if (typeSend === "1") {
        var idBranch = localStorage?.getItem("idBranch");
        if (idBranch === null) {
          navigate("/FormularioMesa");
        }
      } else if (typeSend === "0") {
        var isAddress = localStorage?.getItem("isAddress");
        if (isAddress === null) {
          //      console.log("FormularioDomicilio")
          navigate("/FormularioDomicilio");
        }
      } else if (typeSend === "3") {
        //navigate("/menu");
      } else {
        navigate("/home");
      }
    }
  }, [urlHome]);

  // funciones debe tener un nombre en verbo. acciones.
  const obtenerSubCategorias = async (uuid) => {
    var pleiztUuid = localStorage.getItem("pleiztUuid");
    const urlSubCategorias = `https://dev-api.menu.iguarayalabs.com/category/${uuid}`; // la url debe ser global

    const res = await fetch(urlSubCategorias, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": pleiztUuid,
        "branch-uuid":localStorage.getItem("idBranch"), // este uuid debe ser dinamico.
      }),
    }).then((res) => res.json());
    // console.log(res);

    let infoSubCategoria = res.data.sub_cateogry; // envia toda la informacion del array
    setSubCategorias(infoSubCategoria);
    setCategoriaSeleccionada(infoSubCategoria[0].items);
    borrarBusqueda2();
  };

  // https://dev-api.menu.iguarayalabs.com/home/pleizt-uuid/${uuid}

  // funciones debe tener un nombre en verbo. acciones.
  const obtenerCategoriaSeleccionada = () => {
    let subCategoria = subCategorias[0];
    // console.log("subCategoria: ");
    // console.log(subCategoria);
    if (subCategoria !== undefined && subCategoria.items !== undefined) {
      setCategoriaSeleccionada(subCategoria.items);
    }
  };

  // para formatear el precio. para darle formato a los precios.
  const codigoPais = "es-CO";
  const codigoCurrency = "COP";

  // Metodo de filtrado o busqueda
  const filtrarBusqueda = async (palabraIngresada) => {
    if (palabraIngresada !== "") {
      try {
        // CUARTO ENDPOINT: // buscador en todas las categorias.
        let apiBuscador = `https://dev-api.menu.iguarayalabs.com/search`;


        var pleiztUuid = localStorage.getItem("pleiztUuid");
        const res = await fetch(apiBuscador, {
          method: "post",
          body: JSON.stringify({
            // aqui se arma el json para enviarlo al api, de acuerdo a como esta en postman (POST)
            search: palabraIngresada,
          }),
          headers: new Headers({
            "pleizt-uuid": pleiztUuid,
            "branch-uuid":localStorage.getItem("idBranch"), // este uuid debe ser dinamico.
          }),
        });
        let respuestaJSON = await res.json();
        // console.log("informacion buscador" + respuestaJSON.data);
        let resProductosFiltrados = respuestaJSON.data;
        setProductoFiltrado(resProductosFiltrados);
      } catch (err) {
        console.log(err);
      }
    } else {
      // borra el filtro en la grilla o fila. si le damos click en la x
      setProductoFiltrado(undefined);
    }
  };

  const [buscarPalabra, setBuscarPalabra] = useState("");

  const borrarBusqueda2 = () => {
    setProductoFiltrado(undefined);
  };

  let display = () => {
    return (
      <>
        {(() => {
          return (
            <>
              {(() => {})()}

              <CarruselCategorias
                openCategories={openCategories}
                obtenerSubCategorias={obtenerSubCategorias}
                subCategorias={subCategorias}
                obtenerCategoriaSeleccionada={obtenerCategoriaSeleccionada}
                type_home={type_home}
                filtrarBusqueda={filtrarBusqueda}
                setBuscarPalabra={setBuscarPalabra}
                color_botton={color_botton}
                color_hover={color_hover}
              />
              {(() => {
                if (categoriaSeleccionada) {
                  return (
                    <>
                      {(() => {
                        if (type_home === "list") {
                          return (
                            <MenuListAlternativo
                              categoriaSeleccionada={categoriaSeleccionada}
                              codigoCurrency={codigoCurrency}
                              codigoPais={codigoPais}
                              productoFiltrado={productoFiltrado}
                              color_botton={color_botton}
                              color_hover={color_hover}
                            />
                          );
                        } else if (type_home === "grid") {
                          return (
                            <MenuGrillaAlternativo
                              categoriaSeleccionada={categoriaSeleccionada}
                              codigoCurrency={codigoCurrency}
                              codigoPais={codigoPais}
                              productoFiltrado={productoFiltrado}
                              color_botton={color_botton}
                              color_hover={color_hover}
                            />
                          );
                        } else {
                          return (
                            <MenuGrillaAlternativo
                              categoriaSeleccionada={categoriaSeleccionada}
                              codigoCurrency={codigoCurrency}
                              codigoPais={codigoPais}
                              productoFiltrado={productoFiltrado}
                              color_botton={color_botton}
                              color_hover={color_hover}
                            />
                          );
                        }
                      })()}
                    </>
                  );
                }
              })()}
            </>
          );
        })()}
      </>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <Header propiedadesCompany={propiedadesCompany} />
      </header>
      <>
        {(() => {
          return <>{display()}</>;
        })()}
      </>
    </div>
  );
};

export default MenuCategory;
