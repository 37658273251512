import React, { useEffect, useState } from "react";
import logoGris from "../Imagenes/Btn-Black.svg";
import "../MenuList/MenuList.css";
import "../ListaPedido/ListaPedido.css";
import IcoAtras from "../Imagenes/Ico_back.svg";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import imgUbicacion from "../Imagenes/Ubicacion.svg";

export const OrderSummary = ({
  codigoCurrency,
  codigoPais,
  propiedadesCompany,
}) => {
  console.log(propiedadesCompany.company_logo);
  const { idDetalle } = useParams();
  const [detailOrder, setDetailOrder] = useState([]);
  const [detailOrderItems, setDetailOrderItems] = useState([]);
  const [detailOrderBranch, setDetailOrderBranch] = useState([]);
  const [detailOrderClient, setDetailOrderClient] = useState([]);
  const curlDetailOrder = async () => {
    var idUsuario = localStorage.getItem("idUsuarioLogeado");

    const urlListaDirecciones = `https://dev-api.menu.iguarayalabs.com/detail-order/${idDetalle}`;
    var pleiztUuid = localStorage.getItem("pleiztUuid");
    const respuesta = await fetch(urlListaDirecciones, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": pleiztUuid,
      }),
    }).then((respuesta) => respuesta.json());
    setDetailOrder(respuesta.data);
    setDetailOrderItems(respuesta.data.items);
    setDetailOrderBranch(respuesta.data.branch);
    setDetailOrderClient(respuesta.data.client);
    console.log(respuesta);
  };
  useEffect(() => {
    curlDetailOrder();
  }, []);
  const [isBotonActive, setIsBotonActive] = useState(false);
  return (
    <>
      <div className="container-principal-carrito">
        <div className="container-list-pedido ">
          <div className="head-carrito" style={{ marginTop: "10px" }}>
            <Link to={"/menu"} style={{ textDecoration: "none" }}>
              <div className="container-agregar-tarjeta">
                <img src={IcoAtras} alt="IcoLocacion"></img>
                <p>Atrás</p>
              </div>
            </Link>
            <div className="pedido">
              <img
                className="imagen-perfil"
                src={propiedadesCompany.company_logo}
                alt=""
              ></img>
            </div>
          </div>

          <div className="container-info-orden">
            <h3 className="titulos-h3" style={{ margin: "0px 20px 8px 20px" }}>
              Resumen de pedido
            </h3>
          </div>
          <section style={{ margin: "0px 20px 8px 20px" }}>
            <div className="element-info">
              <div className="container-info-orden">
                <h3 className="fecha-hora"># {detailOrder.uuid}</h3>
                <p id="1" className="tipo--entrega">
                  {detailOrder.status_name}
                </p>
              </div>

              <div className="container-recorrido">
                <div className="container-info-orden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <hr className="linea-punteada"></hr>
                  <img src={imgUbicacion} alt="icoReembolso"></img>
                </div>
                <div className="container-info-orden">
                  <p className="fecha-hora"> {detailOrderBranch.name}</p>
                  <p className="fecha-hora">{detailOrderClient.address}</p>
                </div>
              </div>
            </div>
          </section>
          {detailOrderItems.map((item, index) => (
            <React.Fragment key={item.uuid}>
              <div className="lista-carrito">
                <button
                  key={item.uuid}
                  className="cantidad-seleccionada-foto-lista-pedido"
                >
                  {item.quantity}
                </button>
                <div className="container-foto-plato">
                  <img
                    key={item.uuid}
                    src={item.photo}
                    alt="fotoComida"
                    style={{
                      width: "72px",
                      height: "50px",
                      borderRadius: "5px",
                      objectFit: "cover",
                    }}
                  ></img>
                </div>
                <div className="container-informacion-list">
                  <h4
                    className="informacion"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    {item.name}
                  </h4>
                  <p
                    className="informacion-list"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    {item.observation}
                  </p>
                </div>

                <div className="container-end">
                  <p className="informacion-list-precio">{item.total_value}</p>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ))}

          <div className="fondo-boton-detail" st>
            <section className="container--detalle-pago">
              <div className="element-info">
                <div className="container-info-orden">
                  <p className="fecha-hora">Subtotal</p>
                  <p className="subtotal">{detailOrder.base_value}</p>
                </div>
                <div className="container-info-orden">
                  <p className="fecha-hora">Valor domicilio</p>
                  <p className="fecha-hora">{detailOrder.delivery_value} </p>
                </div>

                <hr className="linea-punteada" />
                <div className="container-info-orden">
                  <h4> Total </h4>
                  <h4>{detailOrder.total_value} </h4>
                </div>
                <hr className="linea-punteada" />
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};