import React, { useEffect, useState } from 'react';

import imgLoader from '../Imagenes/Pedidos.jpg';
import botonMasGrilla from "../Imagenes/ico_agregar-fondo-blanco.svg";
import botonFlotante from "../Imagenes/ImagenCarrito.svg"
import { Link, useNavigate } from 'react-router-dom';
import "./MenuGrillaAlternativo.css";
import { FooterHome } from '../FooterHome/FooterHome';


export const MenuGrillaAlternativo = props => {

    const { categoriaSeleccionada, codigoCurrency, codigoPais, productoFiltrado, color_hover, color_botton } = props;

    const [listaProductosCarrito, setListaProductosCarrito] = useState();

    // cantidad total que aparece en el boton flotante que lleva al carrito.
    var totalCantidadCarrito = 0;

    const pleiztUuid = localStorage.getItem("pleiztUuid");

    useEffect(() => {
        // se esta obteniendo la informacion del localStorage en caso de que haya algo de informacion.
        const informacionLocalStorage = localStorage.getItem("infoProductoSeleccionado");

        // console.log(informacionLocalStorage);

        if(informacionLocalStorage !== undefined && informacionLocalStorage !== null ){
            let parsedRespuesta = JSON.parse(informacionLocalStorage); // JSON.parse convierte la información de string a un objeto de javascript.
           
            setListaProductosCarrito(parsedRespuesta);
        } 
    }, [])


    // trae la informacion
    const idUsuarioLogeado = localStorage.getItem("idUsuarioLogeado");

    let navigateSinUsuario = useNavigate();
    let navigate = useNavigate();
    const pasaAlCarrito = (e) => {

        if(idUsuarioLogeado !== null) {
            navigate("/shoppingCart/");
            // console.log(correoUsuario);
        } else {
            navigateSinUsuario("/IniciarSesion/");
        }

        // if(totalCantidadCarrito > 0 ){
        //     navigate("/shoppingCart/");
        // } else {
        //     alert("¡No tienes productos en el carrito!, agrega un producto");
        // }
    }

    return (
        
        <>

            <div className='contenedor-principal-grilla'>
            <div className='container-grid'>
                {(() => {
                    if(listaProductosCarrito !== undefined){
                        listaProductosCarrito.map((item) => {
                            totalCantidadCarrito += item.cantidadProducto;
                        })
                    }
                          
                    var listaProductos = categoriaSeleccionada;
                    
                    if(productoFiltrado !== undefined) {
                        listaProductos = productoFiltrado;
                    }
                    
                    if(listaProductos.length > 0) {
                        return (
                            listaProductos.map((plato) => {
                            // cantidad de cada plato que se seleccionaron que estan en el carrito.
                            var cantidadProductoCarrito = 0;
                         
                            if(listaProductosCarrito !== undefined) {
                            
                                listaProductosCarrito.map((producto) => {
                                    if(producto.idPlatoSelec === plato.uuid){
                                        cantidadProductoCarrito += producto.cantidadProducto;
                                    }
                                })
                            }
    
                            return(
                                <>
                                    <Link 
                                        key={plato.uuid}
                                        to={ "/detail/" + plato.uuid }
                                        style={{ textDecoration: "none" }}
                                    >
                                    <div className='container-foto-alternativo'>   

                                    {(() => {
                                        if(cantidadProductoCarrito > 0 ){
                                            return (
                                                <button className='cantidad-seleccionada-foto-producto-grilla'>{cantidadProductoCarrito}</button>
                                            )
                                        }
                                    })()}     

                                        {(() => {
                                            if(plato.photo !== "" ){
                                                return (
                                                    <img src={plato.photo} alt='fotoComida' className='fotoComida'
                                                    ></img>
                                                )
                                            } else {
                                                return(
                                                    <img src={imgLoader} alt='fotoComida' className="fotoComida" ></img>
                                                )
                                            }
                                        })()} 
    
    
                                        <div className='container-nombre-boton'>   
                                            <p className='nombre-plato'>{plato.name}</p> 
                                            <img className='btn-mas-grilla' src={botonMasGrilla}></img>
                                        </div>

                                        <div className='container-precio-descuento'>
                                            {(() => {
                                                if(plato.discount.status === true ){
                                                    if( plato.discount.value !== undefined ){
                                                        return (
                                                            <p className='precio-rojo'> {plato.discount.value.toLocaleString(codigoPais, {style:"currency", minimumFractionDigits: 0, maximumFractionDigits:0, currency:codigoCurrency }) } </p>
                                                        )
                                                    }
                                                }
                                            })()}
                                            <p className='precio-producto'> {plato.price.toLocaleString(codigoPais, {style:"currency", minimumFractionDigits: 0, maximumFractionDigits:0, currency:codigoCurrency })}</p>  
                                        </div>

                                        {(() => {
                                            // if(plato.discount.status !== true ){
                                               return (
                                                    <>
                                                        {(() => {
                                                            if(plato.discount.status === true ) {
                                                                if( plato.discount.labels !== undefined ){
                                                                    return (
                                                                        <p className='porcentajeDescuento' style={{  background: `${ plato.discount.colors }` }} > {plato.discount.labels} </p> 
                                                                    )
                                                                }
                                                            }
                                                        })()} 

                                                        {(() => {
                                                            if (plato.tags !== undefined ) {
                                                                return(
                                                                    plato.tags.map( (item) => {
                                                                        return(
                                                                            <>
                                                                                <div className='grupo-etiquetas'>
                                                                                    <p className='etiquetaAzul' style={{  background: `${ item.colors }` }}> {item.label} </p>  
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                )
                                                            } 
                                                        })()}
                                                    </>                                 
                                                )      
                                            // }
                                        })()}
                                    </div> 
                                    </Link>                                    
                                </>  
                            )
                        })
                        )
                    } else {
                        return(<h3>No hay productos para mostrar</h3>)
                    }
                })()}
            </div>
            </div>
        </>
    )
}
