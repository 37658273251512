import { useEffect, useState } from "react";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import Popup from "../../componentes/Popup/Popup";

import { CarruselCategorias } from "../../componentes/CarruselCategorias/CarruselCategorias";
import { CarrionGroup } from "../../componentes/CarruselCategorias/CarrionGroup";
import { CarrionCategoryLink } from "../../componentes/CarruselCategorias/CarrionCategoryLink";

import Banner from "../../componentes/Carrucel/BannerPrueba";
import { Header } from "../../componentes/header/Header";
import { SeccionHomeOrden } from "../../componentes/ListaOrdenes/seccionHomeOrden";
export const Menu = () => {
  let navigate = useNavigate();
  const [openCategories, setOpenCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [openBanners, setOpenBanners] = useState([]);
  const [openPopUp, setOpenPopUp] = useState({});

  const [subCategorias, setSubCategorias] = useState([]);

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState();
  // const {uuid, name, price, photo, description} = categoriaSeleccionada;
  const [propiedadesCompany, setPropiedadesCompany] = useState([]);

  const { type_home, color_hover, color_botton, table_name } =
    propiedadesCompany;
  // aqui se guarda el color del boton azul
  localStorage.setItem("colorBoton", color_botton);
  localStorage.setItem("colorHover", color_hover);

  const pleiztUuids = window.location.hostname.split(".")[0];

  var pleiztUuid;
  if (pleiztUuids === undefined) {
    if (localStorage.getItem("pleiztUuid") === undefined) {
    } else {
      pleiztUuid = localStorage.getItem("pleiztUuid");
    }
  } else {
    pleiztUuid = pleiztUuids;
    if (localStorage.getItem("pleiztUuid") !== pleiztUuid) {
      localStorage.clear();
    }
    localStorage.setItem("pleiztUuid", pleiztUuid);
  }
  const [orderActive, setOrderActive] = useState([]);
  // primer end point o servicio
  const urlHome = `https://dev-api.menu.iguarayalabs.com/home-new`;

  //const [orderActive, setOrderActive] = useState([]);
  useEffect(() => {
    var pleiztService = localStorage.getItem("pleiztService");
    (async function () {
      const res = await fetch(urlHome, {
        method: "get",
        headers: new Headers({
          "pleizt-uuid": pleiztUuid, // y se reemplaza la informacion
          "pleizt-service": pleiztService,
          "branch-uuid": localStorage.getItem("idBranch"),
        }),
      }).then((res) => res.json());
      var mensajeExito = res.success;
      if (mensajeExito === "success") {
        setOpenCategories(res.data.categories); // aqui estoy pasando el objeto categories, no es necesario pasarlo en el map.
        setGroups(res.data.groups);
        setOpenBanners(res.data.banners);
        setOpenPopUp(res.data.popup);
        setPropiedadesCompany(res.properties);
        setOrderActive(res.data.order);
         console.log("respuesta" + res.data.success);
      } else {
        console.log("No hay menus para este restaurante");
      }
    })();
    var typeSend = localStorage?.getItem("typeSend");
    if (typeSend === "1") {
      var idBranch = localStorage?.getItem("idBranch");
      if (idBranch === null) {
        navigate("/FormularioMesa");
      }
    } else if (typeSend === "0") {
      var isAddress = localStorage?.getItem("isAddress");
      if (isAddress === null) {
        //      console.log("FormularioDomicilio")
        navigate("/FormularioDomicilio");
      }
    } else if (typeSend === "3") {
      navigate("/menu");
    } else {
      navigate("/home");
    }
  }, [urlHome]);

  // funciones debe tener un nombre en verbo. acciones.
  const obtenerSubCategorias = async (uuid) => {
    var pleiztUuid = localStorage.getItem("pleiztUuid");
    const urlSubCategorias = `https://dev-api.menu.iguarayalabs.com/category/${uuid}`; // la url debe ser global
    const res = await fetch(urlSubCategorias, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": pleiztUuid, // este uuid debe ser dinamico.
        "branch-uuid":localStorage.getItem("idBranch"), // este uuid debe ser dinamico.
      }),
    }).then((res) => res.json());
    // console.log(res);

    let infoSubCategoria = res.data.sub_cateogry; // envia toda la informacion del array
    setSubCategorias(infoSubCategoria);
  };

  // https://dev-api.menu.iguarayalabs.com/home/pleizt-uuid/${uuid}

  // funciones debe tener un nombre en verbo. acciones.
  const obtenerCategoriaSeleccionada = () => {
    let subCategoria = subCategorias[0];
    if (subCategoria !== undefined && subCategoria.items !== undefined) {
      setCategoriaSeleccionada(subCategoria.items);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <Header propiedadesCompany={propiedadesCompany} />
      </header>
      <div>
        {(() => {
          if (orderActive.status) {
            return (
              <SeccionHomeOrden
                uuid={orderActive.uuid}
                status_name={orderActive.status_name}
                branch={orderActive.branch_name}
                address={orderActive.client_address}
                propiedadesCompany={propiedadesCompany}
              />
            );
          }
        })()}
      </div>
      <div>
        {(() => {
          if (openBanners !== undefined) {
            return <Banner openBanners={openBanners} table_name={table_name} />;
          }
        })()}
      </div>
      <CarrionCategoryLink
        openCategories={openCategories}
        obtenerSubCategorias={obtenerSubCategorias}
        subCategorias={subCategorias}
        type_home={type_home}
      />
      <div>
        {groups?.map((group, i) => {
          return (
            <CarrionGroup openCategories={group.items} title={group.name} />
          );
        })}
      </div>
      {(() => {
        if (openPopUp.status === true) {
          return <Popup imagenPopUp={openPopUp.img} />;
        }
      })()}
    </div>
  );
};
export default Menu;
