import React from "react";
import { useState } from "react";

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import logoGris from "../Imagenes/Btn-Black.svg";

import "./FormularioDomicilio.css";

const FormularioDomicilio = () => {
  const [direccion, setDireccion] = useState();
  const [complemento, setComplemento] = useState();

  const [idCiudadSucursal, setIdCiudadSucursal] = useState();
  const [nameCiudadSucursal, setNameCiudadSucursal] = useState(
    "Selecciona una ciudad"
  );

  const [propiedadesCompanys, setPropiedadesCompany] = useState([]);

  const [listaCiudades, setListaCiudades] = useState([]);
  const [buscarDirreccion, setBuscarDirreccion] = useState();
  const [AddressList, setAddressList] = useState([]);
  const urlHomeApp = `https://dev-api.menu.iguarayalabs.com/home/`;
  const urlBase = `https://dev-api.menu.iguarayalabs.com`;
  const [showSplash, setShowSplash] = useState(false);
  var userId = localStorage.getItem("userId");
  const [uuidAddress, setUuidAddress] = useState("-");

  // HEADERS parametros de seguridad o configuraciones que tiene la pagina y son independientes de la navegacion...  // Header en la pagina. manda informacion especifica a
  useEffect(() => {
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    localStorage.setItem("typeSend", 0);
    if (userId !== null) {
      setShowSplash(true)
      AddressUsers();
    }
    // 
    obtenerCiudades();
  }, [urlHomeApp]);

  const AddressUsers = async () => {
    var userId = localStorage.getItem("userId");
    const urlCiudades = urlBase+`/user/list-address/` + userId;
    const respuesta = await fetch(urlCiudades, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": window.location.hostname.split(".")[0],
      }),
    }).then((respuesta) => respuesta.json());
    setAddressList(respuesta.data);
  };

  const obtenerCiudades = async () => {
    const urlCiudades = urlBase+`/cities`;
    const respuesta = await fetch(urlCiudades, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": window.location.hostname.split(".")[0],
      }),
    }).then((respuesta) => respuesta.json());
    setPropiedadesCompany(respuesta.properties);
    let infoCiudades = respuesta.data;
    setListaCiudades(infoCiudades);
  };
  const OnDelovery = (e) => {
    setShowSplash(false)
  };
  /////////
  const enviarInfoBuscarDireccion = async () => {
    let idCiudad = localStorage.getItem("idCiudadSucursal");
    let direccion = localStorage.getItem("Direccion");
    let complement = localStorage.getItem("complement");

    try {
      let apiBuscarDireccion = urlBase+`/search/address`;
      const res = await fetch(apiBuscarDireccion, {
        method: "post",
        body: JSON.stringify({
          city_id: idCiudad,
          address: direccion,
          complements: complement,
          user_id: userId
        }),
        headers: new Headers({
          "pleizt-uuid": window.location.hostname.split(".")[0],
        }),
      });

      let respuestaJSON = await res.json();
      if (respuestaJSON.message !== "success") {
        alert(respuestaJSON.message);
      } else {
        let resBuscarDireccion = respuestaJSON.data;
        localStorage.setItem("idBranch", resBuscarDireccion.id_branchs);
        localStorage.setItem("uuidAddress", resBuscarDireccion.uuidAddress);
        setBuscarDirreccion(resBuscarDireccion);
        navigate("/menu");
      }
    } catch (err) {
      console.log(err);
    }
  };

  ///////

  const enviarIdCiudad = (idCiudadSucursal, nameCiudadSucursal) => {
    localStorage.setItem("idCiudadSucursal", idCiudadSucursal);
    // console.log(idCiudadSucursal);
    // obtenerTiendasXCiudad(idCiudadSucursal);
    setIdCiudadSucursal(idCiudadSucursal);
    // setCiudad()
    setNameCiudadSucursal(nameCiudadSucursal);
  };

  const guardarTipoEnvio = (id) => {
    // 1. guardar la informacion en localStora
    localStorage.setItem("idTipoEnvio", id);
    // 2. se hace la navegacion >>>
    // 3. si es el tipo
  };

  let navigate = useNavigate();
  const guardarInfo = () => {
    if (nameCiudadSucursal === undefined || direccion === undefined) {
      alert("Por favor ingresar todos los datos");
    } else {
      console.log(buscarDirreccion);
      localStorage.setItem("nameCiudadSucursal", nameCiudadSucursal);
      localStorage.setItem("Direccion", direccion);
      localStorage.setItem("Complemento", complemento);
      localStorage.setItem("complement", complemento);
      localStorage.setItem("isAddress", true);
      enviarInfoBuscarDireccion();
      guardarTipoEnvio("1");
    }
  };
  ////////
  const guardarAddress = () => {
    if (uuidAddress === '-') {
      alert("Por favor, seleccione dirección.");
    } else {
      localStorage.setItem("isAddress", true);
      localStorage.setItem("uuidAddress", uuidAddress);
      navigate("/menu");
    }
  };

  //////

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="containerPrincipal">
        <div className="head-formulario">
          <Link to={"/"}>
            <img
              src={logoGris}
              alt="logoGris"
              style={{
                width: "95px",
                height: "52px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            ></img>
          </Link>
        </div>

        <form className="form-domicilio" onSubmit={onSubmit}>
          <img
            src={propiedadesCompanys.company_logo}
            alt="logoCompany"
            style={{ zIndex: "1", width: "150px", top: "190px" }}
          />
          {showSplash ? (
            <div>
              <div className="subtitulosOpciones">
                <h3 className="titulo-categoria">Seleccione dirección</h3>
              </div>
              <div>
                {(() => {
                  return (
                    AddressList?.map((items, ids) => {
                      return (
                        <label className="radio-container">
                          <input
                            type="radio"
                            name="address"
                            value={items.id}
                            className="radio-input"
                            onChange={(e) =>
                              setUuidAddress(items.id)
                            }
                          />
                          <div className="text-container">
                            <span className="address-text">{items.address}</span>
                            <span className="city-text">{items.complement}</span>
                            <span className="city-text">{items.city_name}</span>
                          </div>
                        </label>);
                    })
                  );
                })()}
              </div>

              <div className="subtitulosOpciones">
                <button
                  className="boton"
                  onClick={guardarAddress}
                  style={{
                    marginTop: "12px",
                    border: "none",
                    cursor: "pointer",
                    width: "290px",
                    borderRadius: "12px",
                    background: "#28304A",
                    height: "50px",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#ffffff",
                  }}
                >
                  Siguiente
                </button>
              </div>
              <div className="subtitulosOpciones">
                <span class="address-text-o">Ó</span>
              </div>
              <div className="subtitulosOpciones1">
                <button onClick={OnDelovery} className="button-new-home">
                  <p className="button-title-new-home">Nueva dirección</p>
                </button>
              </div>

            </div>
          ) : (
            <>
              <h3>Ingresa tu dirección de entrega</h3>
              {(() => {
                if (listaCiudades !== undefined) {
                  return (
                    <Select
                      id="sucursales"
                      className="options"
                      defaultValue={{
                        label: nameCiudadSucursal,
                        value: idCiudadSucursal,
                      }}
                      // options={  optionsCiudades.map(city => ({ label: city.nameCity, value: city.id }))  }
                      options={listaCiudades.map((ciudad) => ({
                        label: `${ciudad.name}`,
                        value: `${ciudad.id}`,
                      }))}
                      onChange={(e) => {
                        enviarIdCiudad(e.value);
                        setNameCiudadSucursal(e.label);
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 12,
                        width: 280,
                      })}
                    />
                  );
                }
              })()}

              <input
                type="text"
                className="new-inputConfirmacion"
                placeholder="Dirección"
                onChange={(e) => setDireccion(e.target.value)}
              ></input>

              <input
                type="text"
                className="new-inputConfirmacion"
                placeholder="Punto de referencia ó casa, apto, torre,"
                onChange={(e) => setComplemento(e.target.value)}
              ></input>

              <button
                className="boton"
                onClick={guardarInfo}
                style={{
                  margin: "40px",
                  border: "none",
                  padding: "9px",
                  cursor: "pointer",
                  width: "272px",
                  borderRadius: "12px",
                  background: "#28304A",
                  height: "50px",
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#ffffff",
                }}
              >
                Guardar
              </button>
            </>
          )
          }








        </form>
      </div>
    </>
  );
};

export default FormularioDomicilio;
