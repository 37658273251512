import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import "./AgregarTarjeta.css";

import logoGris from "../Imagenes/Btn-Black.svg";
import flechaDerecha from "../Imagenes/Ico_back.svg";
import logoMasterCard from "../Imagenes/Mastercard-logo.png";
import imgPerfil from "../Imagenes/Avatar03.svg";
import IcoAtras from "../Imagenes/Ico_back.svg";
import { useState } from 'react';

const AgregarTarjeta = ({propiedadesCompany}) => {
    
    var pleiztUuid =  localStorage.getItem("pleiztUuid");
    const colorBoton = localStorage.getItem("colorBoton");

    const [numeroTarjeta, setNumeroTarjeta] = useState();
    const [mesTarjeta, setMesTarjeta] = useState();
    const [yearTarjeta, setYearTarjeta] = useState();
    const [cvcTarjeta, setCvcTarjeta] = useState();
    const [error, setError] = useState(false);
    
    const [registros, setRegistros] = useState([]);

    const guardarInfoTarjeta = () => {
        if(numeroTarjeta === undefined || mesTarjeta === undefined || yearTarjeta === undefined || cvcTarjeta === undefined ) { 
            alert("Por favor ingresar todos los datos");
        } else {
            // aqui se guarda la informacion en un array 
            var infoForm = {numeroTarjeta, mesTarjeta, yearTarjeta, cvcTarjeta }
            setRegistros([...registros, infoForm]);
            localStorage.setItem("numeroTarjeta", numeroTarjeta);
            localStorage.setItem("mesTarjeta", mesTarjeta);
            localStorage.setItem("yearTarjeta", yearTarjeta);
            localStorage.setItem("cvcTarjeta", cvcTarjeta);
        } 
    }


    useEffect(() => {
        try{
            const localStorageAnwers = localStorage.setItem("registros");

            let parsedRespuesta;

            if(!localStorageAnwers) {
                localStorage.setItem("registros", JSON.stringify(registros)); // envia info en forma de string.

                parsedRespuesta = registros;
            } else {
                parsedRespuesta = JSON.parse(localStorageAnwers.getItem("registros")); // JSON.parse convierte la información de string a un objeto de javascript.
            }
            setRegistros(parsedRespuesta);
        } catch (error) {
            setError(error);
        }
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const nombres = localStorage.getItem("Nombres");

    return (

        <>
            <div className='container-principal-carrito'>
                <div className='container-list-pedido '>
                    {/* <div className='head-carrito'>
                    <Link to={'/listaTarjetas' } >
                        <img src={logoGris} alt='logoGris' style={{ width: "95px", height: "52px", marginTop: "10px", display: "flex", flexDirection: "row", justifyContent: "flex-start" }} ></img>
                    </Link>
                        <h4 className='title-pedido'>Agregar tarjeta</h4>
                    </div> */}

                <div className='head-lista-ordenes' style={{ margin: "20px" }} >
                    <Link to={ '/listaTarjetas' } style={{textDecoration: "none"}} >
                        <div className='container-agregar-tarjeta'>
                            <img src={IcoAtras} alt='IcoLocacion'></img>
                            <p style={{ color: "black" }}>Atrás</p>
                        </div>
                    </Link>
                    <div className='pedido'>
                        <img className='imagen-perfil' src={imgPerfil} alt='IcoLocacion'></img>
                    </div> 
                </div> 





                    <form className='form-agregar-tarjeta' onSubmit={ onSubmit }>
                            
                        <h3> Ingresa los datos de tu tarjeta </h3>        

                        <div className='contenedor-tarjeta'>
                    <div className='tarjeta'>
                        <div className='contenido-tarjeta'>
                            <div className='container-img-logo'>
                                <img src={logoMasterCard} alt='logoGris' style={{ width: "15%", height: "40%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }} ></img>    
                            </div>
                            
                            <div className='container-info-tarjeta'>
                                <p className='parrafo-tarjeta' >{nombres}</p>
                                <p className='parrafo-tarjeta' >-/-</p>
                            </div>
                            <p className='parrafo-tarjeta'>**** **** **** 1234</p>
                        </div>
                    </div>
                </div>

                        <input type="number" className='inputConfirmacion' placeholder='Número de tarjeta' onChange={(e) => setNumeroTarjeta(e.target.value)} min={1}
                        ></input>

                        <div className='container-datos-tc'>
                            <input type="number" className='inputDatosTC' placeholder='Mes' onChange={(e) => setMesTarjeta(e.target.value)} min={1} pattern="[0-9]{0,13}" maxlength="2"  
                            
                            // onInput={"if(value.length > maxLength) value = value.slice(0, maxLength);"}
                            ></input>

                            <input type="number" className='inputDatosTC' placeholder='Año' onChange={(e) => setYearTarjeta(e.target.value)} min={1} ></input>

                            <input type="number" className='inputDatosTC' placeholder='CVC' onChange={(e) => setCvcTarjeta(e.target.value)} min={1} ></input>
                        </div>
                      
                    

                        <button 
                            className="boton" 
                            // onClick={guardarInfo}
                            style={{ margin: "10px", border: "none", padding: "9px", color: "white",
                                cursor: "pointer",
                                width: "272px",
                                borderRadius: "12px", 
                                background: propiedadesCompany.color_botton
                            }} 
                        >
                        Agregar tarjeta
                        </button>        
                    </form>
                </div>  
            </div>
        </>
        


    )
}

export default AgregarTarjeta