import React from "react";
import { useState } from "react";
import PopUpNumPedido from "../PopUpNumeroPedido/PopUpNumPedido";
import { useNavigate } from "react-router-dom";
import "./RegistroUsuario.css";
import Select from "react-select";
import { Header1 } from "../../componentes/header/Header1";
const RegistroGuestsV2 = ({
    enviarInfoOrdenMesa,
    numeroPedidoFinal,
    propiedadesCompany,
}) => {
    const [popUpEntrega, setPopUpEntrega] = useState(false);
    // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.
    const urlBase = `https://dev-api.menu.iguarayalabs.com`;
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [document, setDocument] = useState();
    const [typeDocument, setTypeDocument] = useState(0);

    const options = [
        { value: 1, label: "Cédula de ciudadanía" },
        { value: 2, label: "NIT" },
        { value: 3, label: "Pasaporte" },
        { value: 4, label: "Cédula de extranjería," },
    ];
    let navigate = useNavigate();
    const guardarInfo = async () => {
        if (name === undefined || phone === undefined || email === undefined || document === undefined) {
            alert("Por favor ingresar todos los datos");
        } else if (typeDocument == 0) {
            alert("Seleccione tipo de documento");
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const uuid_address = localStorage.getItem("uuidAddress");
            //setIsValid();
            if (emailPattern.test(email)) {
                if (
                    !isNaN(phone) &&
                    parseInt(phone, 10) >= 3000000000 &&
                    parseInt(phone, 10) <= 7000000000
                ) {
                    var pleiztUuid = localStorage.getItem("pleiztUuid");
                    ///////
                    const UrlUsers = urlBase + '/users/validation/create';
                    const respuesta = await fetch(UrlUsers, {
                        method: "post",
                        body: JSON.stringify({
                            firstname: name,
                            phone: phone,
                            email: email,
                            document: document,
                            typeDocument: typeDocument,
                            uuid_address: uuid_address,
                        }),
                        headers: new Headers({
                            "pleizt-uuid": pleiztUuid,
                        }),
                    }).then((respuesta) => respuesta.json());
                    if (respuesta.success == "success") {
                        let infoListaTiendas = respuesta.data;
                        localStorage.setItem("userTemp", infoListaTiendas.uuid);
                        navigate("/detallePago/");
                    } else {
                        alert(respuesta.message);
                    }
                    navigate("/ValidationPhone/");
                } else {
                    alert("Teléfono no válido : " + phone);
                }
            } else {
                alert("Correo electrónico no válido : " + email);
            }
        }
    };



    const onSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div class="App">
            <div className="containerPrincipal">
                <header className="App-header">
                    <Header1 propiedadesCompany={propiedadesCompany} />
                </header>
                <form className="new-form" onSubmit={onSubmit}>
                    <div className="container-titulo-iniciar-sesion">
                        <div className="new-titulo-iniciar-sesion">
                            Información del cliente
                        </div>
                    </div>

                    <input
                        type="text"
                        className="new-inputConfirmacion-guests"
                        placeholder="Nombre y apellido"
                        onChange={(e) => setName(e.target.value)}
                    ></input>

                    <input
                        type="text"
                        className="new-inputConfirmacion-guests"
                        placeholder="Correo"
                        onChange={(e) => setEmail(e.target.value)}
                    ></input>
                    <input
                        type="number"
                        className="new-inputConfirmacion-guests"
                        placeholder="Teléfono"
                        min={1}
                        onChange={(e) => setPhone(e.target.value)}
                    // onInput={ validacionCelular() }
                    ></input>
                    <Select
                        id="sucursales"
                        className="options"
                        defaultValue={{
                            label: "Tipo de documento",
                            value: 0,
                        }}
                        options={options}
                        onChange={(e) => {
                            setTypeDocument(e.value);
                            // setNameCiudadSucursal(e.label);
                        }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 12,
                        })}
                    />

                    <input
                        type="number"
                        className="new-inputConfirmacion-guests"
                        placeholder="Número documento"
                        min={1}
                        onChange={(e) => setDocument(e.target.value)}
                    ></input>
                    <div className="new-user-text-title">
                        ¿Por qué pedimos estos datos?
                    </div>
                    <div className="new-user-text-description">
                        Para cumplir con la ley de <b>facturación electrónica</b>,
                        necesitamos tu documento de identidad. Garantizamos la seguridad y
                        confidencialidad de tus datos.
                        <br></br>
                        <b>¡Gracias por tu cooperación!</b>
                    </div>

                    <button
                        className="boton"
                        onClick={guardarInfo}
                        style={{
                            marginTop: "20px",
                            marginBottom: "40px",
                            border: "none",
                            color: "white",
                            padding: "9px",
                            cursor: "pointer",
                            height: "40px",
                            borderRadius: "12px",
                            background: "#28304A",
                        }}
                    >
                        Ir a pagar
                    </button>
                </form>
            </div>

            <PopUpNumPedido
                popUpEntrega={popUpEntrega}
                setPopUpEntrega={setPopUpEntrega}
                numeroPedidoFinal={numeroPedidoFinal}
            />
        </div>
    );
};

export default RegistroGuestsV2;
