import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PopUpNumPedido from "../PopUpNumeroPedido/PopUpNumPedido";
import { useNavigate } from "react-router-dom";
import "./RegistroUsuario.css";
import Select from "react-select";
import { Header1 } from "../../componentes/header/Header1";
const RegistroGuestsNew = ({
  enviarInfoOrdenMesa,
  numeroPedidoFinal,
  propiedadesCompany,
}) => {
  const [popUpEntrega, setPopUpEntrega] = useState(false);
  // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [document, setDocument] = useState();
  const [typeDocument, setTypeDocument] = useState(0);
  const [isLogin, setIsLogin] = useState(false);
  //
  const [dataUser, setDataUser] = useState([]);
  const [invocesList, setInvocesList] = useState([]);
  const [uuidinvoces, setUuidinvoces] = useState("-");
  var userId = localStorage.getItem("userId");
  const urlBase = `https://dev-api.menu.iguarayalabs.com`;
  const options = [
    { value: 1, label: "Cédula de ciudadanía" },
    { value: 2, label: "NIT" },
    { value: 3, label: "Pasaporte" },
    { value: 4, label: "Cédula de extranjería," },
  ];
  useEffect(() => {
    if (userId !== null) {
      setIsLogin(true);
      DataUsers();
    }
  }, []);

  const DataUsers = async () => {
    var userId = localStorage.getItem("userId");
    const urlCiudades = urlBase + `/user/invoices/` + userId;
    const respuesta = await fetch(urlCiudades, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": window.location.hostname.split(".")[0],
      }),
    }).then((respuesta) => respuesta.json());
    setDataUser(respuesta.data.user);
    setInvocesList(respuesta.data.invoice);
    //setAddressList(respuesta.data);
  };

  let navigate = useNavigate();
  const guardarInfo = () => {
    if (name === undefined || email === undefined || document === undefined) {
      alert("Por favor ingresar todos los datos");
    } else if (typeDocument == 0) {
      alert("Seleccione tipo de documento");
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      //setIsValid();
      if (emailPattern.test(email)) {
        if (
          !isNaN(phone) &&
          parseInt(phone, 10) >= 3000000000 &&
          parseInt(phone, 10) <= 7000000000
        ) {
          localStorage.setItem("Nombres", name);
          localStorage.setItem("name", name);
          localStorage.setItem("lastname", email);
          localStorage.setItem("email", email);
          localStorage.setItem("phone", phone);
          localStorage.setItem("Celular", phone);
          localStorage.setItem("document", document);
          localStorage.setItem("typedocument", typeDocument);
          navigate("/ValidationPhone/");
        } else {
          alert("Teléfono no válido : " + phone);
        }
      } else {
        alert("Correo electrónico no válido : " + email);
      }
    }
  };


  const guardarInvoces = () => {
    if (uuidinvoces === '-') {
      alert("Por favor, seleccione usario.");
    } else {
      localStorage.setItem("isAddress", true);
      localStorage.setItem("uuidInvoces", uuidinvoces);
      navigate("/detallePago");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div class="App">
      <div className="containerPrincipal">
        <header className="App-header">
          <Header1 propiedadesCompany={propiedadesCompany} />
        </header>
        <div className="new-form">
          <div>
            <h3 className="titulo-categoria">Información del cliente </h3>
            <label className="radio-container">
              <div className="text-container">
                <span className="address-text">{dataUser.name}</span>
                <span className="city-text">{dataUser.email}</span>
                <span className="city-text">{dataUser.phone}</span>
              </div>
            </label>
          </div>
        </div>
        {isLogin ? (
          <div className="new-form">
            <div>
              <h4 className="titulo-categoria">Información de facturación  </h4>
              {(() => {
                return (
                  invocesList?.map((items, ids) => {
                    return (
                      <label className="radio-container">
                        <input
                          type="radio"
                          name="address"
                          value={items.id}
                          className="radio-input"
                          onChange={(e) =>
                            setUuidinvoces(items.id)
                          }
                        />
                        <div className="text-container">
                          <span className="address-text">{items.name}</span>
                          <span className="city-text">{items.email}</span>
                          <span className="city-text">{items.type_document} - {items.nuip}</span>
                        </div>
                      </label>);
                  })
                );
              })()}
            </div>
            <button
              className="boton"
              onClick={guardarInvoces}
              style={{
                marginTop: "20px",
                marginBottom: "40px",
                border: "none",
                color: "white",
                padding: "9px",
                cursor: "pointer",
                height: "40px",
                borderRadius: "12px",
                background: "#28304A",
              }}
            >
              Ir a pagar
            </button>
          </div>
        ) : (<div>
          <form className="new-form" onSubmit={onSubmit}>
            <div className="container-titulo-iniciar-sesion">
              <h3 className="titulo-categoria">Información de facturación </h3>
            </div>

            <input
              type="text"
              className="new-inputConfirmacion-guests"
              placeholder="Nombre y apellido / Nombre Compania"
              onChange={(e) => setName(e.target.value)}
            ></input>

            <input
              type="text"
              className="new-inputConfirmacion-guests"
              placeholder="Correo"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <Select
              id="sucursales"
              className="options"
              defaultValue={{
                label: "Tipo de documento",
                value: 0,
              }}
              options={options}
              onChange={(e) => {
                setTypeDocument(e.value);
                // setNameCiudadSucursal(e.label);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 12,
              })}
            />

            <input
              type="number"
              className="new-inputConfirmacion-guests"
              placeholder="Número documento"
              min={1}
              onChange={(e) => setDocument(e.target.value)}
            ></input>

            <button
              className="boton"
              onClick={guardarInfo}
              style={{
                marginTop: "20px",
                marginBottom: "40px",
                border: "none",
                color: "white",
                padding: "9px",
                cursor: "pointer",
                height: "40px",
                borderRadius: "12px",
                background: "#28304A",
              }}
            >
              Ir a pagar
            </button>
            <div className="new-user-text-title">
              ¿Por qué pedimos estos datos?
            </div>
            <div className="new-user-text-description">
              Para cumplir con la ley de <b>facturación electrónica</b>,
              necesitamos tu documento de identidad. Garantizamos la seguridad y
              confidencialidad de tus datos.
              <br></br>
              <b>¡Gracias por tu cooperación!</b>
            </div>


          </form >
        </div >)}

      </div >

      <PopUpNumPedido
        popUpEntrega={popUpEntrega}
        setPopUpEntrega={setPopUpEntrega}
        numeroPedidoFinal={numeroPedidoFinal}
      />
    </div >
  );
};

export default RegistroGuestsNew;
