import React, {useEffect, useRef, useState} from 'react';
import  iconoMesas  from '../Imagenes/Ico_mesas.svg';
import "./BannerPrueba.css";

import styled from 'styled-components';

const Banner = props => {

    // const [currentIndex, setCurrentIndex] = useState(0);
    const [error, setError] = useState();

    const {openBanners, table_name} = props;
    // console.log("aqui se imprime la info fr banner")
    // console.log(openBanners);

    const slideshow = useRef(null);
    const intervaloSliderShow = useRef(null);


    const anterior = () => {
        // console.log("Anterior");
         // console.log(slideshow); // aqui accedemos al objeto.
        // console.log(slideshow.current); // current es una propiedad y es el valor actual que tiene esta referencia.aqui accedemos al div o al carrusel de las imagenes del movible.


        // Comprueba q el slider tenga elementos
        if(slideshow.current !== null &&  slideshow.current.children.length > 0){

            // Obtenemos el ultimo elemeto del slidershow.
            const index = slideshow.current.children.length -1; 
            // console.log(index);

            const ultimoElemento = slideshow.current.children[index];
            slideshow.current.insertBefore(ultimoElemento, slideshow.current.firstChild);

            slideshow.current.style.transition = "none";

            // aqui se calcula el valor del tamaño del slide
            const tamañoSlide = slideshow.current.children[0].offsetWidth;
            slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;

            setTimeout(() => {
                slideshow.current.style.transition = "2000ms ease-out all";
                // console.log("86: "+ slideshow.current.style)
                slideshow.current.style.transform = `translateX(0)`;
            }, 30)

        }
    }


    // autoplay
    useEffect(() => {
        try {
            intervaloSliderShow.current = setInterval(() => { // permite ejecutar una funcion despues de cierto tiempo.
                anterior();
            }, 5000); // 500milisegundos
    
            // eliminar los intervalos
            slideshow.current.addEventListener("mouseenter", () => {
                clearInterval(intervaloSliderShow.current);
            });
    
            // Volve a poner el intervalo cuando sacan el cursor del slideshow.
            slideshow.current.addEventListener("mouseleave", () => {
                intervaloSliderShow.current = setInterval(() => {
                    anterior();
                }, 5000);
            });
        } catch (error) {
            setError(setInterval);
        }
    }, [])
    

    return (
        
        <Main >
        <ContenedorPrincipal>
            {/* // ref : crea una referencia para react, para acceder a la referencia, y se usa useRef. */}
            {/* <div className='container-iconoMesa'>
                <img src={iconoMesas} alt='imgMesas' className='iconoMesas'/>
                <p className='numeroMesa'> <b> {table_name}</b>  </p>
            </div> */}
            <ContenedorSlideShow ref={slideshow}>
                
                {
                    openBanners.map((imagen, i) => {
                        // console.log(imagen);
                        return(
                            <React.Fragment key={imagen.id}>
                                <Slide> 
                                    <img style={{backgroundImage: `url(${imagen.img})`, backgroundPosition: "center", backgroundSize: "cover", width:"720px", height: "220px", objectFit: "cover"   }}>
                                    </img>
                                </Slide>
                            </React.Fragment>
                        );
                    })
                }
                
            </ContenedorSlideShow>
        </ContenedorPrincipal>
        </Main>
    )
}

const Main = styled.div `
    display: flex
    max-width: 100%;
    max-height: 50%;
    // margin: 10px auto;

    // max-width: 720px; // se quitaron estas dos opciones
    // margin: 7px auto; // se quitaron estas dos opciones

    //max-height: 100px; // se agrego esta opción. pero al agregarla no modifica el tamaño del carrusel solo sube la informacion 

    @media (min-width:400px) and (max-width: 450px){
        max-width: 420px;
    }
`;

const ContenedorPrincipal = styled.div `
    // controles posicionamiento absoluot
    position: relative;
    overflow: hidden;
    // object-fit: cover;

    max-height: 100%; 
    max-height: 50%; 
    max-height: 198px; // ESTE TAMAÑO SI FUNCIONA


    @media (max-width: 100px){

        max-height: 198px;
        object-fit: cover;  
        // esto ajusta el tamaño de la imagen al contenedor
    }

    @media (max-width: 600px){

        max-height: 208px;
        object-fit: cover;  
        // esto ajusta el tamaño de la imagen al contenedor
    }
`;

const ContenedorSlideShow = styled.div `
    display: flex;
    flex-wrap: nowrap; 
    object-fit: cover;
    // si le ponemos un ancho debe ponerse esta opcion.
`;

const Slide = styled.div `
    display: flex;    
    min-width: 100%; // se modifico el tamaño
    overflow: hidden;
    transition: .3s ease all;
    position: relative;
    // z-index: 10;
    cursor: pointer;
    // object-fit: cover; // esto ajusta el tamaño de la imagen al contenedor

    img {
        width: 100%;
        heigth: 50%; 
        object-fit: cover;
    }
`;


export default Banner;