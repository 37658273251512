import React, { useState } from "react";
import "./CarruselCategorias.css";
import imgLoader from "../Imagenes/Pedidos.jpg";

import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Tags = (props) => {
  const { tag,className } = props;
  const codigoPais = "es-CO";
  const codigoCurrency = "COP";
  const formatter = new Intl.NumberFormat(codigoPais, {
    style: "currency",
    currency: codigoCurrency, // Puedes cambiar a 'USD', 'GBP', etc. según la moneda que necesites
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  // settings es el responsive del carrusel o scroll horizontal.
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  return (
    <div>
      {tag.length === 0 ? (
        <p></p>
      ) : (
        tag.map((item, index) => (
          <div
            className={className}
            style={{
              background: item.colors,
            }}
          >
            {item.label}
          </div>
        ))
      )}
    </div>
  );
};
