import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logoPedidos from "../../Imagenes/arrow_left_dark.svg";
import imgLoader from "../Imagenes/Pedidos.jpg";
import btn_plus from "../Imagenes/btn_plus.svg";
import btn_minus from "../Imagenes/btn_minus.svg";
import botonFlotante from "../../Imagenes/boton_order.svg";

import RadioInputSeleccion from "../DetalleProducto/RadioInputSeleccion";
import "./DetalleAlternativo.css";
import { useParams } from "react-router-dom"; // lo importo al componente al cual quiero ir o enviar datos
import { Header } from "../../componentes/header/Header";
export const DetalleAlternativo = ({ codigoCurrency, codigoPais }) => {
  // console.log("company_logo detalle alternativo: ");
  // console.log(company_logo);

  const [infoProductoSeleccionado, setinfoProductoSeleccionado] = useState([]);
  const { uuid, name, description, price, units, toppings_groups, tags } =
    infoProductoSeleccionado; // desestructuracion desde el api en este mismo componente

  const [infoPropiedades, setInfoPropiedades] = useState([]);
  const { color_botton, color_hover } = infoPropiedades;

  const [cantidadProducto, setCantidadProducto] = useState(1);
  const [precioTotal, setPrecioTotal] = useState(price);
  const [toppingsSeleccionados, setToppingsSeleccionados] = useState([]); // este viene de RadioInputSeleccion.

  const [photo, setPhoto] = useState(imgLoader);
  const [descuento, setDescuento] = useState({});
  const { colors } = descuento;
  const { idDetalleAlternativo } = useParams(); // uuid del plato seleccionado
  const [propiedadesCompany, setPropiedadesCompany] = useState([]);
  const apiDetalle = `https://dev-api.menu.iguarayalabs.com/item-detail/${idDetalleAlternativo}`;


  var pleiztUuid = localStorage.getItem("pleiztUuid");

  useEffect(() => {
    (async function () {
      const res = await fetch(apiDetalle, {
        method: "get",
        headers: new Headers({
          "pleizt-uuid": pleiztUuid,
        }),
      }).then((res) => res.json());

      setPhoto(res.data.photo);
      setinfoProductoSeleccionado(res.data);
      setInfoPropiedades(res.properties);
      setDescuento(res.data.discount);
      setPropiedadesCompany(res.properties);
    })();
  }, [apiDetalle]);

  const agregarInfoLocalStorage = () => {
    // crear el json
    const productoSeleccionado = {
      idPlatoSelec: uuid,
      nombrePlatoSelec: name,
      descripcionPlatoSelec: description,
      fotoPlatoSelec: photo,
      precio: price,
      cantidadProducto: cantidadProducto,
      toppings: toppingsSeleccionados,
    };
    // se esta obteniendo la informacion del localStorage en caso de que haya algo de informacion. //
    const informacionLocalStorage = localStorage.getItem(
      "infoProductoSeleccionado"
    );

    if (
      informacionLocalStorage === undefined ||
      informacionLocalStorage === null
    ) {
      var listaProductos = [productoSeleccionado];
      localStorage.setItem(
        "infoProductoSeleccionado",
        JSON.stringify(listaProductos)
      );
    } else {
      let parsedRespuesta = JSON.parse(informacionLocalStorage); // JSON.parse convierte la información de string a un objeto de javascript.
      var listaProductos = [...parsedRespuesta, productoSeleccionado];

      localStorage.setItem(
        "infoProductoSeleccionado",
        JSON.stringify(listaProductos)
      );
    }
  };

  let navigate = useNavigate();
  const validacionCantidad = (e) => {
    if (cantidadProducto !== undefined) {
      var productoSePuedeGuardarCarrito = true;

      var statusObligatorio = true;
      var pendienteName;
      var cantidadMinima;

      if (toppings_groups !== undefined) {
        const newState = toppings_groups.map((item) => {
          if (item.type === 0) {
            productoSePuedeGuardarCarrito = false;
            if (statusObligatorio === true) {
              let filtro = toppingsSeleccionados.filter(
                (categoria) => categoria.uuid === item.uuid
              );

              var categoriaGuardada = filtro[0];
              if (item.cant_min === 0) {
                productoSePuedeGuardarCarrito = true;
              } else {
                if (categoriaGuardada !== undefined) {
                  if (
                    categoriaGuardada.topingsSeleccionados.length >=
                    item.cant_min
                  ) {
                    productoSePuedeGuardarCarrito = true;
                  } else {
                    statusObligatorio = false;
                    pendienteName = item.name;
                    cantidadMinima = item.cant_min;
                  }
                } else {
                  statusObligatorio = false;
                  pendienteName = item.name;
                  cantidadMinima = item.cant_min;
                }
              }
            }
          }
        });
      }
      if (productoSePuedeGuardarCarrito === true) {
        agregarInfoLocalStorage();
        window.history.back()
      } else {
        alert(
          "Te faltan toppings por seleccionar en " +
            pendienteName +
            ", cantidad mínima: " +
            cantidadMinima
        );
      }
    }
  };

  var toppingsGuardados = [];
  const guardarToppingsSeleccionados = (toppings) => {
    toppingsGuardados = toppings;
    totalProductos(cantidadProducto);
    setToppingsSeleccionados(toppings);
  };

  const sumarProducto = () => {
    if (cantidadProducto > 0) {
      var suma = cantidadProducto + 1;
      setCantidadProducto(suma);
      totalProductos(suma);
    }
  };

  const restarProducto = () => {
    if (cantidadProducto > 1) {
      var resta = cantidadProducto - 1;
      setCantidadProducto(resta);
      totalProductos(resta);
    }
  };

  const totalProductos = (cantidadProducto) => {
    if (toppingsGuardados.length === 0) {
      // el array toppingsGuardados esta vacio.
      // Si esta vacio entonces lo actualiza con la informacion que tiene en el set toppingsSeleccionados
      toppingsGuardados = toppingsSeleccionados;
    }

    var sumaToppings = 0;
    if (toppingsGuardados !== undefined) {
      toppingsGuardados.map((item) => {
        if (item !== undefined) {
          item.topingsSeleccionados.map((topping) => {
            sumaToppings = sumaToppings + topping.price;
          });
        }
      });
    }
    var resultado = (parseInt(price) + sumaToppings) * cantidadProducto;
    setPrecioTotal(resultado);
  };

  return (
    <React.Fragment key={uuid}>
      <div className="container-principal" key={uuid}>
        <div className="encabezado-comida-alternativo">
          <div className="espacio-elementos">
            <div className="texto-encabezado-detalle">
              <Link
                to="javascript:void(0)"
                onClick={() => window.history.back()}
              >
                <img src={logoPedidos}></img>
              </Link>
            </div>

            {(() => {
              if (price !== undefined) {
                return (
                  <p key={price} className="precio-alternativo">
                    {" "}
                    {price.toLocaleString(codigoPais, {
                      style: "currency",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                      currency: codigoCurrency,
                    })}{" "}
                  </p>
                );
              }
            })()}

            {(() => {
              if (descuento.status === true) {
                if (descuento.value !== undefined) {
                  return (
                    <>
                      <p
                        key={descuento.value}
                        className="precio-tachado-rojo-foto-1"
                      >
                        {" "}
                        {descuento.value.toLocaleString(codigoPais, {
                          style: "currency",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          currency: codigoCurrency,
                        })}{" "}
                      </p>
                    </>
                  );
                }
              }
            })()}
          </div>

          {(() => {
            if (descuento.status === true) {
              return (
                <p
                  key={descuento.labels}
                  className="porcentaje-descuento-detalle-2"
                  style={{ background: `${descuento.colors}` }}
                >
                  {" "}
                  {descuento.labels}{" "}
                </p>
              );
            }
          })()}

          <div className="grupo-etiquetas-alternativo">
            {(() => {
              if (tags !== undefined) {
                return tags.map((item) => {
                  return (
                    <p
                      key={item.label}
                      className="etiqueta-texto-3"
                      style={{ background: `${item.colors}` }}
                    >
                      {" "}
                      {item.label}{" "}
                    </p>
                  );
                });
              }
            })()}
          </div>
        </div>

        <div className="container-imagen-cabecera-alternativo">
          {(() => {
            if (photo !== "") {
              return (
                <img
                  src={photo}
                  alt="imagenCabecera"
                  className="imgEncabezado"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "0px 0px 25px 25px",
                  }}
                ></img>
              );
            } else {
              return (
                <img
                  src={imgLoader}
                  alt="imagenCabecera"
                  className="imgEncabezado"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "0px 0px 25px 25px",
                  }}
                ></img>
              );
            }
          })()}
        </div>

        <section className="container-options-detalle-alternativo ">
          <div className="container-titulo">
            <h3 key={name} id={name} className="titulo-plato">
              {name}
            </h3>
          </div>

          <div className="container-info">
            <p key={description} id={description} className="parrafo">
              {description}
            </p>
          </div>

          {(() => {
            if (toppings_groups !== undefined) {
              return (
                <RadioInputSeleccion
                  tituloTopping={toppings_groups}
                  codigoCurrency={codigoCurrency}
                  codigoPais={codigoPais}
                  guardarToppingsSeleccionados={guardarToppingsSeleccionados}
                  color_botton={color_botton}
                  color_hover={color_hover}
                />
              );
            }
          })()}
        </section>
        {(() => {
          var typeSend = localStorage?.getItem("typeSend");
          if (typeSend < 2) {
            return (
              <div className="container-btn-flotante">
                <div className="footer">
                  <div className="container-precio">
                    <img src={btn_minus} onClick={restarProducto}></img>

                    {(() => {
                      if (precioTotal !== undefined) {
                        return (
                          <p>
                            {cantidadProducto}
                            {/*precioTotal.toLocaleString(codigoPais, {
                              style: "currency",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              currency: codigoCurrency,
                            })*/}{" "}
                          </p>
                        );
                      }
                      if (price !== undefined) {
                        return (
                          <p>
                            {cantidadProducto}
                            {/*price.toLocaleString(codigoPais, {
                              style: "currency",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              currency: codigoCurrency,
                            })*/}
                          </p>
                        );
                      }
                    })()}
                    <img src={btn_plus} onClick={sumarProducto}></img>
                    {(() => {
                      if (precioTotal !== undefined) {
                        return (
                          <p>
                            {precioTotal.toLocaleString(codigoPais, {
                              style: "currency",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              currency: codigoCurrency,
                            })}{" "}
                          </p>
                        );
                      }
                      if (price !== undefined) {
                        return (
                          <p>
                            {price.toLocaleString(codigoPais, {
                              style: "currency",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              currency: codigoCurrency,
                            })}
                          </p>
                        );
                      }
                    })()}
                  </div>

                  <div className="container-btn-flotante-detalle">
                    <img
                      src={botonFlotante}
                      className="new-boton-order"
                      onClick={(e) => validacionCantidad()}
                    ></img>
                  </div>
                </div>
              </div>
            );
          }
        })()}
      </div>
    </React.Fragment>
  );
};
