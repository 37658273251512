import React from "react";
import "../MenuList/MenuList.css";
import "./ListaOrdenes.css";

export const DepositBank = ({
    bank,
    number_acount,
    type_acount,
    total_value
}) => {
  return (
    <section className="container-orden-actual-detalle">
      <div className="element-info">
        <div className="container-info-orden">
          <p className="fecha-hora">
            <b>ORDEN PENDIENTE DE PAGO</b>
          </p>
        </div>
        <div className="container-info-orden">
          <p className="fecha-hora">
            Por favor, proceda a realizar una transferencia utilizando los
            siguientes datos:
          </p>
        </div>

        <div className="container-info-orden">
          {/* <div className='pedido'> */}
          <div className="container-agregar-tarjeta">
            <div className="pedido">
              <p className="fecha-hora">
                Banco : <b>{bank}</b>
              </p>
              <p className="fecha-hora">
                Cuenta #: <b>{number_acount}</b>
              </p>
              <p className="fecha-hora">
                Tipo: <b>{type_acount}</b>
              </p>
              <p className="fecha-hora">
                Valor: <b>{total_value}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
