import React, { useEffect, useState } from "react";
import logoGris from "../Imagenes/Btn-Black.svg";
import "../MenuList/MenuList.css";
import "./ListaPedido.css";
import boton from "../Imagenes/Btn+.svg";
import imgPerfil from "../Imagenes/Avatar03.svg";
import imgPerfil2 from "../Imagenes/img-perfil.jpeg";
import botonMas from "../Imagenes/Btn+.svg";
import botonMenos from "../Imagenes/Btn-.svg";
import IcoAtras from "../Imagenes/Ico_back.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Header1 } from "../../componentes/header/Header1";
import PopUpEntrega from "../TipoEntrega/PopUpEntrega";

export const ListaPedido = ({
  codigoCurrency,
  codigoPais,
  propiedadesCompany,
}) => {
  const [lista, setLista] = useState([]); // lista de productos para manipularla y mostrarla.
  const [precioTotal, setPrecioTotal] = useState(0);
  // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.

  var pleiztUuid = localStorage.getItem("pleiztUuid");

  var nameUsuarioLogeado = localStorage.getItem("nameUsuarioLogeado");
  var apellidoUsuarioLogeado = localStorage.getItem("apellidoUsuarioLogeado");

  useEffect(() => {
    obtenerProductos();
  }, []);

  const colorBoton = localStorage.getItem("colorBoton");

  var userId = localStorage.getItem("userId");

  let navigateDetallePago = useNavigate();
  const obtenerProductos = () => {
    // se esta obteniendo la informacion del localStorage en caso de que haya algo de informacion.
    const informacionLocalStorage = localStorage.getItem(
      "infoProductoSeleccionado"
    );

    if (
      informacionLocalStorage !== undefined &&
      informacionLocalStorage !== null
    ) {
      let parsedRespuesta = JSON.parse(informacionLocalStorage); // JSON.parse convierte la información de string a un objeto de javascript.
      var products = [];
      var precioTotal = 0;
      parsedRespuesta.map((item) => {
        precioTotal += obtenerPrecioProducto(item);
        products.push(item);
      });
      setPrecioTotal(precioTotal);
      setLista(products);

      // navigateDetallePago('/detallePago/');
    } else {
      alert("No tienes productos en el carrito!! :(");
    }
  };

  const agregarProducto = (index) => {
    var producto = lista[index];
    var nuevaCantidad = producto.cantidadProducto + 1;
    lista[index].cantidadProducto = nuevaCantidad;
    console.log("lista cantidad" + lista[index].cantidadProducto);
    var listaActualizada = lista;
    localStorage.setItem(
      "infoProductoSeleccionado",
      JSON.stringify(listaActualizada)
    );
    obtenerProductos();
  };

  const restarProducto = (index) => {
    var producto = lista[index];
    var nuevaCantidad = producto.cantidadProducto - 1;
    if (nuevaCantidad > 0) {
      lista[index].cantidadProducto = nuevaCantidad;
      console.log("lista cantidad" + lista[index].cantidadProducto);
    } else {
      // eliminar producto de la lista, y corre
      // var array = [0,1,5,3];
      // var array.splice(2, 1) // elimina la posicion 2 del array. el numero
      if (index > -1) {
        lista.splice(index, 1); // el numero 1 mueve las posiciones despues del index una posicion en el array.
      }
    }

    var listaActualizada = lista;
    localStorage.setItem(
      "infoProductoSeleccionado",
      JSON.stringify(listaActualizada)
    );
    obtenerProductos();
  };

  const obtenerPrecioProducto = (producto) => {
    //var precioTotal = producto.precio * producto.cantidadProducto
    var precioTopping = 0;

    if (producto.toppings !== undefined) {
      producto.toppings.map((item) => {
        if (item.topingsSeleccionados !== undefined) {
          item.topingsSeleccionados.map((topping) => {
            precioTopping = precioTopping + topping.price;
          });
        }
      });
    }
    var precioTotal = producto.precio + precioTopping;
    precioTotal = precioTotal * producto.cantidadProducto;
    return precioTotal;
  };

  const borrarCarrito = (e) => {
    e.preventDefault();
    localStorage.removeItem("infoProductoSeleccionado");
    setPrecioTotal(0);
    setLista([]);
  };

  const direccionUsuario = localStorage.getItem("Direccion");
  const nameTienda = localStorage.getItem("nameTienda");
  const tipoEntrega = "Domicilio"; //localStorage.getItem("TipoEntrega");

  const idUsuarioLogeado = localStorage.getItem("idUsuarioLogeado");

  let navigateSinUsuario = useNavigate();
  let navigate = useNavigate();
  const pasaAlCarrito = (e) => {
    if (idUsuarioLogeado !== null) {
      navigate("/shoppingCart/");
   
    } else {
      navigateSinUsuario("/detallePago/");
      alert("No tienes productos en el carrito!! :(");
    }
  };

  const infoProductoSeleccionado = localStorage.getItem(
    "infoProductoSeleccionado"
  );

  let navigateConProductos = useNavigate();
  let navigateCarrito = useNavigate();
  const guests = localStorage.getItem("guests");
  const pasaAlDetallePago = (e) => {
    if (infoProductoSeleccionado !== null) {
      localStorage.setItem("precioTotal", precioTotal);
      if (userId !== null) {
        navigate("/invoice/");
      }else{
        navigate("/RegistroGuests/");
      }
      
    } else {
      navigateCarrito("/shoppingCart/");
      alert("No tienes productos en el carrito!! :( ");
    }
  };

  return (
    <div class="App">
      <div className="container-principal-carrito">
        <header className="App-header">
          <Header1 propiedadesCompany={propiedadesCompany} />
        </header>
        <div className="container-list-pedido ">
        
          <div className="container-boton-borrar">
            <div className="container-info-entrega">
              <p className="title-tipo--entrega">Tipo entrega: {tipoEntrega}</p>

              {(() => {
                if (tipoEntrega === "Domicilio") {
                  return (
                    <p className="title-tipo--entrega">
                      Direccion: {direccionUsuario}{" "}
                    </p>
                  );
                } else {
                  return (
                    <p className="title-tipo--entrega">
                      Direccion: {nameTienda}{" "}
                    </p>
                  );
                }
              })()}
            </div>

            <button
              style={{
                padding: "12px",
                borderRadius: "12px",
                border: "none",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                color: "white",
                background: "#28304A",
              }}
              onClick={borrarCarrito}
            >
              <p style={{ display: "flex", justifyContent: "center" }}>
                Borrar
              </p>
            </button>
          </div>

          {lista.map((item, index) => (
            <React.Fragment key={item.idPlatoSelec}>
              <div className="lista-carrito">
                {(() => {
                  if (item.cantidadProducto > 0) {
                    return (
                      <button
                        key={item.idPlatoSelec}
                        className="cantidad-seleccionada-foto-lista-pedido"
                      >
                        {item.cantidadProducto}
                      </button>
                    );
                  }
                })()}
                <div className="container-foto-plato">
                  <img
                    key={item.idPlatoSelec}
                    src={item.fotoPlatoSelec}
                    alt="fotoComida"
                    style={{
                      width: "72px",
                      height: "50px",
                      borderRadius: "5px",
                      objectFit: "cover",
                    }}
                  ></img>
                </div>
                <div className="container-informacion-list">
                  <h4
                    className="informacion"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    {item.nombrePlatoSelec}
                  </h4>
                  <p
                    className="informacion-list"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    {item.descripcionPlatoSelec}
                  </p>
                </div>

                <div className="container-end">
                  <p className="informacion-list-precio">
                    {" "}
                    {obtenerPrecioProducto(item).toLocaleString(codigoPais, {
                      style: "currency",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                      currency: codigoCurrency,
                    })}
                  </p>
                  <div className="container-botones">
                    <img
                      className="btn-menos-carrito"
                      src={botonMenos}
                      onClick={() => restarProducto(index)}
                    ></img>
                    <img
                      className="btn-mas-carrito"
                      src={botonMas}
                      onClick={() => agregarProducto(index)}
                    ></img>
                  </div>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ))}

          <div className="fondo-boton">
            <div className="container-subtotal">
              <div className="pedido-subtotal">
                <p className="title-subtotal">Subtotal</p>
                <h3 className="subtitle-subtotal">
                  {precioTotal.toLocaleString(codigoPais, {
                    style: "currency",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    currency: codigoCurrency,
                  })}
                </h3>
              </div>

              <button
                onClick={() => pasaAlDetallePago()}
                style={{
                  borderRadius: "12px",
                  border: "none",
                  padding: "12px 40px 12px 40px",
                  cursor: "pointer",
                  margin: "0px 20px 15px 20px",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  width: "-webkit-fill-available",
                  height: "48px",
                  background: "#28304A",
                  fontSize: "16px",
                  fontWeight: 600
                }}
              >
                Ir a pagar
              </button>
            </div>
          </div>
          {/* <PopUpEntrega popUpEntrega={popUpEntrega} setPopUpEntrega={setPopUpEntrega} /> */}
        </div>
      </div>
    </div>
  );
};
