import "../../App.css";
import "./Header.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import iconsLeft from "../../Imagenes/arrow_left.svg";

export const Header2 = (props) => {
  const { propiedadesCompany } = props;
  const [listaProductosCarrito, setListaProductosCarrito] = useState();
  var totalCantidadCarrito = 0;
  useEffect(() => {
    const informacionLocalStorage = localStorage.getItem(
      "infoProductoSeleccionado"
    );
    if (
      informacionLocalStorage !== undefined &&
      informacionLocalStorage !== null
    ) {
      let parsedRespuesta = JSON.parse(informacionLocalStorage); // JSON.parse convierte la información de string a un objeto de javascript.

      setListaProductosCarrito(parsedRespuesta);
    }
  }, []);
  if (listaProductosCarrito !== undefined) {
    listaProductosCarrito.map((item) => {
      totalCantidadCarrito += item.cantidadProducto;
    });
  }
  return (
    <div className="header1">
      <div className="left">
        <Link to="/menu">
          <img
            src={iconsLeft}
            alt="Logo de la aplicación"
            className="header-arrow-left"
          />
        </Link>
      </div>
      <div className="center">
        <img
          src={propiedadesCompany.company_logo}
          className="header-company-logo"
          alt="logo"
        />
      </div>
      <div className="right"></div>
    </div>
  );
};

export default Header2;
