import React, { useEffect, useState } from "react";
import "../MenuList/MenuList.css";
import "./ListaOrdenes.css";
import { Link } from "react-router-dom";

export const SeccionHomeOrden = ({ uuid, status_name, branch, address,propiedadesCompany }) => {
  return (
    <Link to={"/resumenPedido/" + uuid} style={{ textDecoration: "none" }}>
      <section className="container-orden-actual-home">
        <div className="element-info">
          <div className="container-info-orden">
            <p className="fecha-hora"># {uuid}</p>
            <p id="1" className="tipo--entrega">
              {status_name}
            </p>
          </div>

          <div className="container-info-orden">
            {/* <div className='pedido'> */}
            <div className="container-agregar-tarjeta">
              <img
                className="imagen-perfil"
                src={propiedadesCompany.company_logo}
                alt="imgPerfil"
              ></img>
              <div className="pedido">
                <p className="fecha-hora">{branch}</p>
                <p className="fecha-hora">Entrega: {address}</p>                
              </div>
            </div>
          </div>
        </div>
      </section>
    </Link>
  );
};
