import React, { useEffect, useState } from "react";
import imgPerfil from "../Imagenes/Avatar03.svg";
import IcoLocacion from "../Imagenes/Ico_Locacion.svg";
import IcoAtras from "../Imagenes/Ico_back.svg";
import imgRecorrido from "../Imagenes/Recorrido.svg";
import imgChat from "../Imagenes/Frame.svg";
import imgEstrellas from "../Imagenes/imgEstrellas.svg";
import imgUbicacion from "../Imagenes/Ubicacion.svg";
import barritaCerrar from "../Imagenes/Barrita-close.svg";
import imgMapaFondo from "../Imagenes/map.png";
import "./SeguimientoPedido.css";
import { Link, useNavigate } from "react-router-dom";
import { FooterHome } from "../FooterHome/FooterHome";

export const SeguimientoPedido = ({
  propiedadesCompany,
  codigoCurrency,
  codigoPais,
}) => {
  const [lista, setLista] = useState([]); // lista de productos para manipularla y mostrarla.
  const [isBotonActive, setIsBotonActive] = useState(false);
  // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.
  const [precioTotal, setPrecioTotal] = useState(0);

  var pleiztUuid = localStorage.getItem("pleiztUuid");

  const nombreTienda = localStorage.getItem("nameTienda");
  const direccionUsuario = localStorage.getItem("Direccion");
  const tipoEntrega = localStorage.getItem("TipoEntrega");
  var idUser = localStorage.getItem("idUsuarioLogeado");

  useEffect(() => {
    obtenerProductos();
  }, []);
  const colorBoton = localStorage.getItem("colorBoton");

  // let navigateDetallePago = useNavigate();
  const obtenerProductos = () => {
    // se esta obteniendo la informacion del localStorage en caso de que haya algo de informacion.
    const informacionLocalStorage = localStorage.getItem(
      "infoProductoSeleccionado"
    );

    if (
      informacionLocalStorage !== undefined &&
      informacionLocalStorage !== null
    ) {
      let parsedRespuesta = JSON.parse(informacionLocalStorage); // JSON.parse convierte la información de string a un objeto de javascript.
      var products = [];
      var precioTotal = 0;
      parsedRespuesta.map((item) => {
        precioTotal += obtenerPrecioProducto(item);
        products.push(item);
      });
      setPrecioTotal(precioTotal);
      setLista(products);

      // navigateDetallePago('/detallePago/');
    }
  };

  const obtenerPrecioProducto = (producto) => {
    //var precioTotal = producto.precio * producto.cantidadProducto
    var precioTopping = 0;

    if (producto.toppings !== undefined) {
      producto.toppings.map((item) => {
        if (item.topingsSeleccionados !== undefined) {
          item.topingsSeleccionados.map((topping) => {
            precioTopping = precioTopping + topping.price;
          });
        }
      });
    }
    var precioTotal = producto.precio + precioTopping;
    precioTotal = precioTotal * producto.cantidadProducto;
    return precioTotal;
  };

  return (
    <>
      <div
        className="container-imagen-fondo"
        style={{ backgroundImage: `url(${imgMapaFondo}` }}
      >
        <div className="container-principal-lista-ordenes">
          <div className="container-lista-ordenes">
            {/* <MenuDesplegable propiedadesCompany={propiedadesCompany} /> */}

            <div className="head-lista-ordenes" style={{ marginTop: "10px" }}>
              <Link to={"/listaOrdenes"} style={{ textDecoration: "none" }}>
                <div className="container-agregar-tarjeta">
                  <img
                    className="boton-atras"
                    src={IcoAtras}
                    alt="IcoLocacion"
                  ></img>
                  <p>Atrás</p>
                </div>
              </Link>

              <div className="pedido">
                <img
                  className="imagen-perfil"
                  src={imgPerfil}
                  alt="IcoLocacion"
                ></img>
              </div>
            </div>

            {/* <div className='container-info-orden'> */}
            <h3
              className="titulos-h3"
              style={{ margin: "0px", marginTop: "10px" }}
            >
              Pedidos
            </h3>
            {/* </div> */}

            <section className="container-sub-titulos">
              <div className="container-agregar-tarjeta">
                <img src={IcoLocacion} alt="IcoLocacion"></img>
                {(() => {
                  if (tipoEntrega === "Domicilio") {
                    return <p className="subtitulos"> {direccionUsuario} </p>;
                  } else {
                    return (
                      <p className="title-tipo--entrega"> {nombreTienda} </p>
                    );
                  }
                })()}
              </div>
            </section>

            <section className="container-recorrido-real">
              <img src={imgRecorrido} alt="icoReembolso"></img>
              <section className="container-sub-titulos">
                <div className="container-agregar-tarjeta">
                  <img src={IcoLocacion} alt="IcoLocacion"></img>
                  <p className="subtitulos"> Tu pedido está en camino </p>
                </div>
              </section>
            </section>

            <div className="container--footer-home">
              {/* <section className='container-pepido-recorrido' > */}
              <section
                className={`container-pepido-recorrido ${
                  isBotonActive ? "" : "active"
                }`}
                onClick={() => {
                  setIsBotonActive(!isBotonActive);
                }}
              >
                <div className="contenedor-imagen">
                  <img
                    className="imagen-barrita"
                    src={barritaCerrar}
                    alt="logoGris"
                    onClick={() => {
                      setIsBotonActive(!isBotonActive);
                    }}
                  ></img>
                </div>

                <div className="element-info">
                  <div className="container-info-orden">
                    <p className="fecha-hora"># AB123CD</p>
                    <p id="1" className="tipo--entrega">
                      Entrega a las 12:10pm{" "}
                    </p>
                  </div>

                  <div className="container-info-orden">
                    {/* <div className='pedido'> */}
                    <div className="container-agregar-tarjeta">
                      <img
                        className="imagen-perfil"
                        src={imgPerfil}
                        alt="imgPerfil"
                      ></img>
                      <div className="pedido">
                        <img
                          className="imagen-perfil"
                          src={imgEstrellas}
                          alt="imgEstrellas"
                        ></img>
                        <p className="fecha-hora">Pepe Perez</p>
                      </div>
                    </div>

                    <img
                      className="imagen-perfil"
                      src={imgChat}
                      alt="imgChat"
                    ></img>
                  </div>

                  <div className="container-recorrido">
                    <div className="container-info-orden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                      </svg>
                      <hr className="linea-punteada"></hr>
                      <img src={imgUbicacion} alt="icoReembolso"></img>
                    </div>
                    <div className="container-info-orden">
                      <p className="fecha-hora">Pizza Planet</p>
                      <p className="fecha-hora">Tu mesa</p>
                    </div>
                  </div>
                </div>
              </section>

              {/* {(() => {
                    if(isBotonActive === true) {
                        return(
                            <>
                                <div className='container--footer-home' >
                                    <section className='container-pepido-recorrido2' >
                                    {
                                    lista.map((item, index) => 
                                        <React.Fragment key={item.idPlatoSelec} >
                                            <div className='lista-carrito'>
                                                {(() => {
                                                    if(item.cantidadProducto > 0 ){
                                                        return (
                                                            <button key={item.idPlatoSelec} className='cantidad-seleccionada-foto-lista-pedido'>{item.cantidadProducto}</button>
                                                        )
                                                    }
                                                })()}
                                                <div className='container-foto-plato'>
                                                    <img key={item.idPlatoSelec} src={item.fotoPlatoSelec} alt='fotoComida' style={{ width: "72px", height: "50px", borderRadius: "5px", objectFit: "cover" }} ></img>
                                                </div>
                                                <div className='container-informacion-list'>
                                                    <h4 className='informacion' style={{margin:"0px", padding:"0px"}}>{item.nombrePlatoSelec}</h4> 
                                                    <p className='informacion-list' style={{margin:"0px", padding:"0px"}} >{item.descripcionPlatoSelec}</p>
                                                </div>


                                                <div className='container-end'>
                                                    <p className='informacion-list-precio'>  { obtenerPrecioProducto(item).toLocaleString( codigoPais, {style:"currency", minimumFractionDigits: 0, maximumFractionDigits:0, currency:codigoCurrency }) }</p>
                                                    </div>
                                                </div>
                                                <hr />
                                            </React.Fragment>
                                        )
                                    }
                                    </section>
                                </div>
                            </>
                        )
                    }
                })()} */}
            </div>

            {/* <PopUpRecorridoPedido /> */}

            {(() => {
              if (isBotonActive === true) {
                return (
                  <>
                    <div className="container--footer-home">
                      <section className="container-pepido-recorrido2">
                        {lista.map((item, index) => (
                          <React.Fragment key={item.idPlatoSelec}>
                            <div className="lista-carrito">
                              {(() => {
                                if (item.cantidadProducto > 0) {
                                  return (
                                    <button
                                      key={item.idPlatoSelec}
                                      className="cantidad-seleccionada-foto-lista-pedido"
                                    >
                                      {item.cantidadProducto}
                                    </button>
                                  );
                                }
                              })()}
                              <div className="container-foto-plato">
                                <img
                                  key={item.idPlatoSelec}
                                  src={item.fotoPlatoSelec}
                                  alt="fotoComida"
                                  style={{
                                    width: "72px",
                                    height: "50px",
                                    borderRadius: "5px",
                                    objectFit: "cover",
                                  }}
                                ></img>
                              </div>
                              <div className="container-informacion-list">
                                <h4
                                  className="informacion"
                                  style={{ margin: "0px", padding: "0px" }}
                                >
                                  {item.nombrePlatoSelec}
                                </h4>
                                <p
                                  className="informacion-list"
                                  style={{ margin: "0px", padding: "0px" }}
                                >
                                  {item.descripcionPlatoSelec}
                                </p>
                              </div>

                              <div className="container-end">
                                <p className="informacion-list-precio">
                                  {" "}
                                  {obtenerPrecioProducto(item).toLocaleString(
                                    codigoPais,
                                    {
                                      style: "currency",
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                      currency: codigoCurrency,
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                            <hr className="linea-punteada2" />
                          </React.Fragment>
                        ))}
                      </section>
                    </div>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </>
  );
};
