import React from "react";
import { useState } from "react";
import PopUpNumPedido from "../PopUpNumeroPedido/PopUpNumPedido";
import { useNavigate } from "react-router-dom";
import "./RegistroUsuario.css";
import Select from "react-select";
import { Header1 } from "../../componentes/header/Header1";
const RegistroValidarPhone = ({
    enviarInfoOrdenMesa,
    numeroPedidoFinal,
    propiedadesCompany,
}) => {
    const [popUpEntrega, setPopUpEntrega] = useState(false);
    // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.

    const [phone, setPhone] = useState();
    const urlBase = `https://dev-api.menu.iguarayalabs.com`;

    let navigate = useNavigate();
    const guardarInfo = async () => {
        if (phone === undefined) {
            alert("Por favor ingresar todos los datos");
        } else {
            var pleiztUuid = localStorage.getItem("pleiztUuid");
            var UuidUser = localStorage.getItem("userTemp");

            const UrlUsers = urlBase + '/users/validation/' + UuidUser + '/' + phone;
            const respuesta = await fetch(UrlUsers, {
                method: "get",
                headers: new Headers({
                    'pleizt-uuid': pleiztUuid,
                })
            }).then((respuesta) => respuesta.json());

            if (respuesta.success == "success") {
                let infoListaTiendas = respuesta.data;
                localStorage.setItem("userId", infoListaTiendas.user_id);
                navigate("/detallePago/");
            }else{
                alert(respuesta.message);
            }

        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div class="App">
            <div className="containerPrincipal">
                <header className="App-header">
                    <Header1 propiedadesCompany={propiedadesCompany} />
                </header>
                <form className="new-form" onSubmit={onSubmit}>
                    <div className="container-titulo-iniciar-sesion">
                        <div className="new-titulo-iniciar-sesion">
                            Validar número de celular
                        </div>
                    </div>
                    <input
                        type="number"
                        className="new-inputConfirmacion-guests"
                        placeholder="Codigo"
                        onChange={(e) => setPhone(e.target.value)}
                    // onInput={ validacionCelular() }
                    ></input>
                    <div className="new-user-text-description">
                        Validamos tu número de celular para proteger tu cuenta, evitar fraudes y asegurar una comunicación rápida.
                        <br></br>
                        <br></br>
                        <b>¡Gracias por tu cooperación!</b>
                    </div>

                    <button
                        className="boton"
                        onClick={guardarInfo}
                        style={{
                            marginTop: "20px",
                            marginBottom: "40px",
                            border: "none",
                            color: "white",
                            padding: "9px",
                            cursor: "pointer",
                            height: "40px",
                            borderRadius: "12px",
                            background: "#28304A",
                        }}
                    >
                        Validar
                    </button>
                </form>
            </div>

            <PopUpNumPedido
                popUpEntrega={popUpEntrega}
                setPopUpEntrega={setPopUpEntrega}
                numeroPedidoFinal={numeroPedidoFinal}
            />
        </div>
    );
};

export default RegistroValidarPhone;
