import React, { useState } from 'react';
import './CarruselCategorias.css';
import imgLoader from '../Imagenes/Pedidos.jpg';

import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export const CarruselCategorias = props => {

    const { openCategories, obtenerSubCategorias, filtrarBusqueda, setBuscarPalabra } = props;
    const [ isBotonActive, setIsBotonActive ] = useState(false);
    const [indexCategoriaSeleccionada, setIndexCategoriaSeleccionada] = useState(0);
    const borrarBusqueda = () => {
      setBuscarPalabra("");
      filtrarBusqueda("");
  }
    // settings es el responsive del carrusel o scroll horizontal.
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      };


      function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", borderRadius: "100%" }}
            onClick={onClick}
          />
        );
      }
      
      function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", borderRadius: "100%" }}
            onClick={onClick}
          />
        );
      }
      


    return (
        
        <div className='CarruselCategorias'>
          <Slider {...settings}> 
            {
                openCategories?.map((item, i) => {  
                    return (
                        <div className='card' key={item.uuid} >
                            <div className='card-top' 
                                onClick={ () => {obtenerSubCategorias(item.uuid); setIsBotonActive(!isBotonActive); setIndexCategoriaSeleccionada(i); borrarBusqueda() }} 
                            >
                                <img src={item.photo} alt="imagen"  className="imagenCategoria" />
                                  <div            
                                    className='new-name-categories'                                    
                                    $isBotonActive={ i === indexCategoriaSeleccionada }
                                    onClick={ () => {obtenerSubCategorias(item.uuid); setIsBotonActive(!isBotonActive); setIndexCategoriaSeleccionada(i)  }}
                                  >{item.name}
                                  </div>  
                            </div>
                        </div>       
                    )      
                })
            }
          </Slider>  
        </div>
    )
}

