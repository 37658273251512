import "../../App.css";
import "./Header.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import iconsCart from "../../Imagenes/icons_cart.svg";
import iconsLeft from "../../Imagenes/arrow_left.svg";
import icoSearch from "../../Imagenes/ico_buscar.svg";
import { FaRegClock, FaFacebook, FaTiktok,FaInstagram } from "react-icons/fa"; 
export const Header3 = (props) => {
    const { propiedadesCompany,configHeader } = props;
    const [listaProductosCarrito, setListaProductosCarrito] = useState();
    var totalCantidadCarrito = 0;
    useEffect(() => {
        const informacionLocalStorage = localStorage.getItem(
            "infoProductoSeleccionado"
        );
        if (
            informacionLocalStorage !== undefined &&
            informacionLocalStorage !== null
        ) {
            let parsedRespuesta = JSON.parse(informacionLocalStorage); // JSON.parse convierte la información de string a un objeto de javascript.

            setListaProductosCarrito(parsedRespuesta);
        }
    }, []);
    if (listaProductosCarrito !== undefined) {
        listaProductosCarrito.map((item) => {
            totalCantidadCarrito += item.cantidadProducto;
        });
    }
    return (
        <div>
            <div className="header3" style={{ backgroundImage: "url(" + configHeader.header_img + ")" }}>
                <div className="left">
                    <Link to="/">
                        <img
                            src={iconsLeft}
                            alt="Logo de la aplicación"
                            className="header-arrow-left"
                        />
                    </Link>
                </div>

                <div className="right">
                    {(() => {
                        var typeSend = localStorage?.getItem("typeSend");
                        if (typeSend < 2) {
                            if (totalCantidadCarrito > 0) {
                                return (
                                    <div>
                                        <div className="header-quantity-cart">
                                            <div className="header-quantity-cart-1">
                                                {totalCantidadCarrito}
                                            </div>
                                        </div>
                                        <Link to="/shoppingCart">
                                            <div className="cart">
                                                <img
                                                    src={iconsCart}
                                                    className="header-boton-cart"
                                                    alt="cart"
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                );
                            } else {
                                return (
                                    <Link to="/shoppingCart">
                                        <div className="cart">
                                            <img
                                                src={iconsCart}
                                                className="header-boton-cart"
                                                alt="cart"
                                            />
                                        </div>
                                    </Link>
                                );
                            }
                        }
                    })()}
                </div>
            </div>
            <div className="restaurant-info-container">
                <div className="restaurant-header">
                    <img src={propiedadesCompany.company_logo} alt={propiedadesCompany.company_name} className="restaurant-logo" />
                    <div className="restaurant-details">
                        <h2 className="restaurant-name">{propiedadesCompany.company_name}</h2>
                        <div className="restaurant-rating">
                            <span className="icon-info"><FaFacebook /></span>
                            <span className="icon-info"><FaTiktok /></span>
                            <span className="icon-info"><FaInstagram /></span>
                            <span className="icon-info"><FaFacebook /></span>                            
                        </div>
                    </div>
                </div>
                <div className="restaurant-delivery">
                    <span><FaRegClock /> {configHeader.time}</span>
                    <span>{configHeader.text}</span>                    
                </div>
            </div>
        </div>
    );
};

export default Header3;
