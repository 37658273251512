import React, { useEffect, useState } from "react";

import imgLoader from "../Imagenes/Pedidos.jpg";
import botonMas from "../Imagenes/ico_agregar-fondo-blanco.svg";
import botonFlotante from "../Imagenes/ImagenCarrito.svg";

import { Link, useNavigate } from "react-router-dom";
import "./MenuListAlternativo.css";
import { FooterHome } from "../FooterHome/FooterHome";

export const MenuListAlternativo3 = (props) => {
    const {
        categoriaSeleccionada,
        codigoCurrency,
        codigoPais,
        productoFiltrado,
        color_botton,
        color_hover,
    } = props;
    const [listaProductosCarrito, setListaProductosCarrito] = useState();

    // cantidad total que aparece en el boton flotante que lleva al carrito.
    var totalCantidadCarrito = 0;
    const pleiztUuid = localStorage.getItem("pleiztUuid");

    useEffect(() => {
        // se esta obteniendo la informacion del localStorage en caso de que haya algo de informacion.
        const informacionLocalStorage = localStorage.getItem(
            "infoProductoSeleccionado"
        );

        if (
            informacionLocalStorage !== undefined &&
            informacionLocalStorage !== null
        ) {
            let parsedRespuesta = JSON.parse(informacionLocalStorage); // JSON.parse convierte la información de string a un objeto de javascript.
            setListaProductosCarrito(parsedRespuesta);
        }
    }, []);

    // // trae la informacion
    const idUsuarioLogeado = localStorage.getItem("idUsuarioLogeado");
    const guests = localStorage.getItem("guests");

    let navigateSinUsuario = useNavigate();
    let navigate = useNavigate();
    const pasaAlCarrito = (e) => {
        if (idUsuarioLogeado !== null) {
            const uuid_address = localStorage.getItem("uuid_address");
            if (uuid_address !== null) {
                navigate("/shoppingCart/");
            } else {
                navigateSinUsuario("/misdirecciones/");
            }
            // console.log(correoUsuario);
        } else {
            console.log(guests);
            if (guests === "true") {
                navigate("/shoppingCart/");
            } else {
                navigateSinUsuario("/IniciarSesion/");
            }
        }
    };

    return (
        <>
            <div className="container-list-alternativo">
                {(() => {
                    if (listaProductosCarrito !== undefined) {
                        listaProductosCarrito.map((item) => {
                            totalCantidadCarrito += item.cantidadProducto;
                        });
                    }

                    var listaProductos = categoriaSeleccionada;

                  
                    if (listaProductos.length > 0) {
                        return listaProductos.map((platos) => {
                            // cantidad de cada plato que se seleccionaron que estan en el carrito.
                            var cantidadProductoCarrito = 0;

                            if (listaProductosCarrito !== undefined) {
                                listaProductosCarrito.map((producto) => {
                                    if (producto.idPlatoSelec === platos.uuid) {
                                        cantidadProductoCarrito += producto.cantidadProducto;
                                    }
                                });
                            }

                            return (
                                <>
                                    <React.Fragment key={platos.uuid}>
                                        <Link
                                            to={
                                                "/detail/" + platos.uuid
                                            }
                                            style={{ textDecoration: "none" }}
                                            key={platos.uuid}
                                        >
                                            <div
                                                className="container-second-lista-alternativa"
                                                key={platos.uuid}
                                            >
                                                <div className="container-foto-plato-lista">
                                                    {(() => {
                                                        if (cantidadProductoCarrito > 0) {
                                                            return (
                                                                <button className="cantidad-seleccionada-foto-producto-lista">
                                                                    {cantidadProductoCarrito}
                                                                </button>
                                                            );
                                                        }
                                                    })()}

                                                    {(() => {
                                                        if (platos.photo !== "") {
                                                            return (
                                                                <img
                                                                    key={platos.photo}
                                                                    id={platos.photo}
                                                                    src={platos.photo}
                                                                    alt="fotoComida"
                                                                    style={{
                                                                        width: "145px",
                                                                        height: "105px",
                                                                        borderRadius: "7px",
                                                                        objectFit: "cover",
                                                                    }}
                                                                ></img>
                                                            );
                                                        } else {
                                                            return (
                                                                <img
                                                                    src={imgLoader}
                                                                    alt="fotoComida"
                                                                    className="fotoComida"
                                                                    style={{
                                                                        width: "145px",
                                                                        height: "105px",
                                                                        borderRadius: "7px",
                                                                        objectFit: "cover",
                                                                    }}
                                                                ></img>
                                                            );
                                                        }
                                                    })()}

                                                    {/* 20% */}
                                                    {(() => {
                                                        if (platos.discount.status === true) {
                                                            if (platos.discount.labels !== undefined) {
                                                                return (
                                                                    <p
                                                                        key={platos.discount.labels}
                                                                        className="porcentaje-descuento-lista"
                                                                        style={{
                                                                            background: `${platos.discount.colors}`,
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {platos.discount.labels}{" "}
                                                                    </p>
                                                                );
                                                            }
                                                        }
                                                    })()}

                                                    <div className="container-grupo-etiquetas">
                                                        {(() => {
                                                            // if(platos.discount.status !== true ){
                                                            return (
                                                                <React.Fragment key={platos.name + "2"}>
                                                                    <>
                                                                        {(() => {
                                                                            if (platos.tags !== undefined) {
                                                                                return platos.tags.map(
                                                                                    (item, index) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div
                                                                                                    key={item.label}
                                                                                                    className="grupo-etiquetas-lista"
                                                                                                >
                                                                                                    <p
                                                                                                        key={item.label}
                                                                                                        id={item.label}
                                                                                                        className="etiqueta-azul-lista"
                                                                                                        style={{
                                                                                                            background: `${index === 0 &&
                                                                                                                `${item.colors}`
                                                                                                                }`,
                                                                                                        }}
                                                                                                    >
                                                                                                        {" "}
                                                                                                        {index === 0 &&
                                                                                                            `${item.label}`}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    }
                                                                                );
                                                                            }
                                                                        })()}
                                                                    </>
                                                                </React.Fragment>
                                                            );
                                                            // }
                                                        })()}
                                                    </div>
                                                </div>

                                                <div className="container-informacion-list-alternativo">
                                                    <h4
                                                        id={platos.name}
                                                        key={platos.name}
                                                        className="informacion"
                                                        style={{ margin: "0px", padding: "0px" }}
                                                    >
                                                        {platos.name}
                                                    </h4>
                                                    <p
                                                        id={platos.description}
                                                        key={platos.description}
                                                        className="informacion-list"
                                                        style={{ margin: "0px", padding: "0px" }}
                                                    >
                                                        {platos.description}
                                                    </p>

                                                    {(() => {
                                                        if (platos.tags !== undefined) {
                                                            return platos.tags.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="grupo-etiquetas-lista">
                                                                            <p
                                                                                key={item.label}
                                                                                id={item.label}
                                                                                className="etiqueta-azul-lista"
                                                                                style={{
                                                                                    background: `${index === 1 && `${item.colors}`
                                                                                        }`,
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                {index === 1 && `${item.label}`}{" "}
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                );
                                                            });
                                                        }
                                                    })()}

                                                    <div className="container-grupo-precios">
                                                        <p key={platos.price} className="precio-lista">
                                                            {platos.price.toLocaleString(codigoPais, {
                                                                style: "currency",
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                                currency: codigoCurrency,
                                                            })}
                                                        </p>

                                                        {(() => {
                                                            if (platos.discount.status === true) {
                                                                if (platos.discount.value !== undefined) {
                                                                    return (
                                                                        <p
                                                                            key={platos.discount.value}
                                                                            className="precio-rojo-lista"
                                                                        >
                                                                            {" "}
                                                                            {platos.discount.value.toLocaleString(
                                                                                codigoPais,
                                                                                {
                                                                                    style: "currency",
                                                                                    minimumFractionDigits: 0,
                                                                                    maximumFractionDigits: 0,
                                                                                    currency: codigoCurrency,
                                                                                }
                                                                            )}{" "}
                                                                        </p>
                                                                    );
                                                                }
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="lineaMenu" />
                                        </Link>

                                        {/* <div className='container-btn-flotante-menu-list'  >
                                                <img src={botonFlotante} className='boton-flotante'  onClick={ (e) => pasaAlCarrito() }></img>
                                                {(() => {
                                                    if(totalCantidadCarrito > 0 ){
                                                        return(
                                                            <button key={totalCantidadCarrito} 
                                                            id='btn-cantidad-carrito' className='cantidad-seleccionada-carrito'>{totalCantidadCarrito}</button>
                                                        )
                                                    } 
                                                })()}
                                            </div> */}
                                        {/*  */}


                                    </React.Fragment>
                                </>
                            );
                        });
                    } else {
                        return <h3>No hay productos para mostrar</h3>;
                    }
                })()}
            </div>
        </>
    );
};
