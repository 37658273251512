import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";

const PopUpNumPedido = props => {
    
    const { numeroPedidoFinal,  popUpEntrega, setPopUpEntrega }  = props;

    var pleiztUuid =  localStorage.getItem("pleiztUuid");

    const [popupState, setPopupState] = useState(true);
    // false por defecto de la pagina, se pone true para que aparezca la ventana en la pagina.

    const [ isBotonActive, setIsBotonActive ] = useState(false);


    return (
        <>
            {popUpEntrega && 

            <Overlay className="Overlay" > {/* fondo oscuro */}
                <ContenedorModal>
                    <Titulo>Tú orden fue creada con el número de pedido {numeroPedidoFinal}.</Titulo>
                    {/* <p> { props  =>   props.numeroPedidoFinal}</p> */}
                    <ContenidoEnvio className='Contenido'>
                        <>
                        {/* <img src={imagenPopUp} alt='img popup'/> */}
                        <Link to={'/'+ pleiztUuid}  style={{ textDecoration:"none" }} >   
                            <Boton  
                                // isBotonActive={ i === indexCategoriaSeleccionada }
                                id='botonPedido'
                            ><p className='nombre-subcategoria'>Aceptar</p>
                            </ Boton >
                        </Link>

                        </>
                    </ContenidoEnvio>

                    <BotonCerrar className='BotonCerrar' onClick={() => setPopUpEntrega(false)} > 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </BotonCerrar>
                </ContenedorModal>
            </Overlay>
            }
        </>
    )    
}



const ContenidoEnvio = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // position: absolute; 
    // padding: 20px;
    margin: 35px;

    img {
        width: 100%;
        vertical-align: top;
        border-radius: 5px;
    }
    `;

    const Overlay = styled.div `
        width: 100vw;
        height: 115vh;
        // position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        position: absolute; 
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute; 
    `;

    const ContenedorModal = styled.div`
        // display: flex;
        // justify-content: center;
        // width: 430px;
        // height: 600px;
        // min-height: 100px;
        // background: #fff;
        position: relative; 
        // z-index: 1;
        // border-radius: 7px;
        // box-shadow: rgba(100,100, 111, 0.2) 0px 7px 29px 0px;
        // position: absolute;

        position: fixed;
        transform: translateX(-50%);
        // transform: translateY(-10%);
        transform: translateY(-80%);
    
        width: 430px;
        // height: 600px;
        min-height: 100px;
        background: #fff;
        border-radius: 7px;

        @media (min-width:0px) and (max-width:300px) {
            width: 230px;
            // height: 440px;
        }

        @media (min-width:301px) and (max-width:400px) {
            width: 290px;
            // height: 500px;
        }

        @media (min-width:401px) and (max-width:500px) {
            width: 340px;
            // height: 560px;
        }

        @media (min-width:500px) and (max-width:600px) {
            width: 320px;
            // height: 480px;
        }

        @media (min-width:601px) and (max-width:830px) {
            width: 430px;
            // height: 660px;
        }
    `;

    const BotonCerrar = styled.div`
        position: absolute;
        top: 15px;
        right: 20px; 
        
        width: 30px;
        height: 30px;
        border: none;
        background: none;
        cursor: pointer;
        transition: .3s ease all;
        border-radius: 5px;
        color: #1766DC;

        &:hover {
            background:#f2f2f2;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    `;

    const colorBoton = localStorage.getItem("colorBoton");
    const Boton = styled.button`
        border: none;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        color: white;
        fontSize: 10px;
        width: 110px;
        background: ${  colorBoton  }
    `;

    const Titulo = styled.p`
        border: none;
        border-radius: 5px;
        // padding: 10px;
        color: #4D4D4D;
        fontSize: 10px;
        margin: 38px;
        fontWeight: 400;
        // background: blue
        // --black-100, #4D4D4D
    `;

export default PopUpNumPedido;