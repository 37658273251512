import React, { useEffect, useState } from "react";
import "../MenuList/MenuList.css";
import "../ListaPedido/ListaPedido.css";
import { useParams } from "react-router-dom";
import imgUbicacion from "../Imagenes/Ubicacion.svg";
import { Header2 } from "../../componentes/header/Header2";
import { DepositBank } from "../../componentes/ListaOrdenes/DepositBank";
import MapComponent from '../../componentes/ListaOrdenes/MapComponent';
export const Seguimiento = ({
  propiedadesCompany,
  codigoPais,
  codigoCurrency,
}) => {
  const { idDetalle } = useParams();
  const [detailOrder, setDetailOrder] = useState([]);
  const [detailOrderItems, setDetailOrderItems] = useState([]);
  const [detailOrderBranch, setDetailOrderBranch] = useState([]);
  const [detailOrderClient, setDetailOrderClient] = useState([]);
  const [wireTransfer, setWireTransfert] = useState([]);
  const [status, setStatus] = useState(100);
  const curlDetailOrder = async () => {
    const urlListaDirecciones = `https://dev-api.menu.iguarayalabs.com/detail-order/${idDetalle}`;
   // var pleiztUuid = localStorage.getItem("pleiztUuid");
    var pleiztUuid = window.location.hostname.split(".")[0];
    const respuesta = await fetch(urlListaDirecciones, {
      method: "get",
      headers: new Headers({
        "pleizt-uuid": pleiztUuid,
      }),
    }).then((respuesta) => respuesta.json());
    setDetailOrder(respuesta.data);
    setDetailOrderItems(respuesta.data.items);
    setDetailOrderBranch(respuesta.data.branch);
    setDetailOrderClient(respuesta.data.client);
    setWireTransfert(respuesta.data?.wire_transfer);
    setStatus(respuesta.data.status);
  };
  useEffect(() => {
    curlDetailOrder();
  }, []);
 
  useEffect(() => {
    const updatePage = () => {
        curlDetailOrder();
    };
    const interval = setInterval(updatePage, 10000);
    return () => clearInterval(interval);
  }, [status]);





  return (
    <div className="App">
      <div className="container-principal-carrito ">
        <div className="container-list-pedido ">
          <header className="App-header">
            <Header2 propiedadesCompany={propiedadesCompany} />
          </header>

          <div className="container-info-orden">
            <h3 className="titulos-h3" style={{ margin: "0px 0px px 20px" }}>
              Resumen de pedido
            </h3>
          </div>
          {(() => {
            if (
              detailOrder.payment_method === "bank_deposit" &&
              detailOrder.status === "100"
            ) {
              return (
                <DepositBank
                  bank={wireTransfer?.bank}
                  number_acount={wireTransfer?.number_acount}
                  type_acount={wireTransfer?.type_acount}
                  total_value={detailOrder.total_value}
                />
              );
            }
          })()}

          <section style={{ margin: "0px 20px 8px 20px" }}>
            <div className="element-info">
              <div className="container-info-orden">
                <h3 className="fecha-hora"># {detailOrder.uuid}</h3>
                <p id="1" className="tipo--entrega">
                  {detailOrder.status_name}
                </p>
              </div>

              <div className="container-recorrido">
                <div className="container-info-orden">
                  <p className="fecha-hora"> {detailOrderBranch.name}</p>
                  <p className="fecha-hora">{detailOrderClient.address}</p>
                </div>
                <div className="container-info-orden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <hr className="linea-punteada"></hr>
                  <img src={imgUbicacion} alt="icoReembolso"></img>
                </div>
                
              </div>
            </div>
            {detailOrderBranch.longitude !== undefined && detailOrder.status<9 && (
               <MapComponent
               detailOrder={detailOrder}           
              />
            )}
           
          </section>
         
          {detailOrderItems.map((item, index) => (
            <React.Fragment key={item.uuid}>
              <div className="lista-carrito">
                <button
                  key={item.uuid}
                  className="cantidad-seleccionada-foto-lista-pedido"
                >
                  {item.quantity}
                </button>
                <div className="container-foto-plato">
                  <img
                    key={item.uuid}
                    src={item.photo}
                    alt="fotoComida"
                    style={{
                      width: "72px",
                      height: "50px",
                      borderRadius: "5px",
                      objectFit: "cover",
                    }}
                  ></img>
                </div>
                <div className="container-informacion-list">
                  <h4
                    className="informacion"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    {item.name}
                  </h4>
                  <p
                    className="informacion-list"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    {item.observation}
                  </p>
                </div>

                <div className="container-end">
                  <p className="informacion-list-precio">{item.total_value}</p>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ))}

          <div className="fondo-boton-detail" st>
            <section className="container--detalle-pago">
              <div className="element-info">
                <div className="container-info-orden">
                  <p className="fecha-hora">Subtotal</p>
                  <p className="subtotal">{detailOrder.base_value}</p>
                </div>
                <div className="container-info-orden">
                  <p className="fecha-hora">Valor domicilio</p>
                  <p className="fecha-hora">{detailOrder.delivery_value} </p>
                </div>

                <hr className="linea-punteada" />
                <div className="container-info-orden">
                  <h4> Total </h4>
                  <h4>{detailOrder.total_value}</h4>
                </div>
                <hr className="linea-punteada" />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
