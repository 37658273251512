import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Iconos personalizados para los marcadores
const redIcon = new L.Icon({
  iconUrl: 'https://delivery-iguaraya.s3.amazonaws.com/pin-mapa.png',
  iconSize: [40, 40],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
const HomeIcon = new L.Icon({
    iconUrl: 'https://oms-pleizt-sas.s3.amazonaws.com/social/Pleizt_01.png',
    iconSize: [40, 40],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

/*

const redIcon = new L.Icon({
  iconUrl: 'https://domiciliarios.s3.amazonaws.com/fav/favicon.ico',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
*/

const blueIcon = new L.Icon({
    iconUrl: 'https://domiciliarios.s3.amazonaws.com/fav/favicon.ico',
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const MapComponent =  ({
    detailOrder
  }) => {
  const [position, setPosition] = useState([detailOrder.branch.latitude,detailOrder.branch.longitude]);
  const [branch, setBranch] = useState([detailOrder.branch.latitude,detailOrder.branch.longitude]);
  const [client, setClient] = useState([detailOrder.client.latitude,detailOrder.client.longitude]);
  const [delivery, setDelivery] = useState([detailOrder.delivery.latitude,detailOrder.delivery.longitude]);
  const mapRef = useRef(null);



  const traking = async () => {
    const urlListaDirecciones = `https://dev-api.menu.iguarayalabs.com/track/${detailOrder.uuid}`;
    const respuesta = await fetch(urlListaDirecciones, {
      method: "get",     
    }).then((respuesta) => respuesta.json());
    console.log(respuesta.data.delivery.latitude); 
    setPosition([
      respuesta.data.delivery.latitude,
      respuesta.data.delivery.longitude
    ]);
    if (mapRef.current) {
      mapRef.current.setView(position, 13); // Ajusta el zoom a 13 y centra en el marcador
    }
  };

  // Cambiar la posición del marcador cada 15 segundos
   useEffect(() => {
    const interval = setInterval(() => {
      traking();
      
      /**/
    }, 30000);
    return () => clearInterval(interval);
  }, [position]);

  return (
    <MapContainer center={position} zoom={13} ref={mapRef} className="leaflet-container">
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
        <Marker key={1} position={branch} icon={HomeIcon}>
            <Popup>
            {detailOrder.branch.name}
            </Popup>
        </Marker>
        <Marker key={2} position={client} icon={redIcon}>
            <Popup>
            {detailOrder.client.address}
            </Popup>
        </Marker>     
      <Marker position={position} icon={blueIcon}>
        <Popup>
            Delivery
        </Popup>
      </Marker>
    </MapContainer>
  );
};
export default MapComponent;