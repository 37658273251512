import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "./Home.css";
import IcoMenu from "../../Imagenes/ico_menu.svg";
import icoCcollect from "../../Imagenes/ico_ collect.svg";
import IcoBookings from "../../Imagenes/ico_bookings.svg";
import IcoBranch from "../../Imagenes/ico_branch.svg";
import IcoDelivery from "../../Imagenes/ico_delivery.svg";

import logoPleizt from "../../Imagenes/logo_pleizt.svg";
import icoHead from "../../Imagenes/ico_head.svg";
import IcoLinkedIn from "../../Imagenes/linkedIn.svg";

export const Splash = ({ backgroundColor, imagenHead }) => {
  let navigate = useNavigate();
  localStorage.setItem("mostrarPopUp", true);
  useEffect(() => {
    document.body.style.background = "#28304A"; // Ejemplo: deshabilitar el desplazamiento
  }, []);
  const onLinkClick = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 1);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioMesa");
  };
  const OnDelovery = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 0);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioDomicilio");
  };
  const OnMenu = (e) => {
    e.preventDefault();
    localStorage.setItem("typeSend", 3);
    localStorage.setItem("pleiztUuid", window.location.hostname.split(".")[0]);
    navigate("/FormularioMesa");
  };

  const OnBooking = (e) => {
    e.preventDefault();
    const nuevaURL =
      "https://reservas.pleizt.co/" + window.location.hostname.split(".")[0];
    // Redireccionar a la nueva URL
    window.location.href = nuevaURL;
  };

  return (
    <React.Fragment>
      <div
        className="container-new-principal-home"
        style={{
          background: "#28304A",
        }}
      >
        <div className="container-imagen-cabecera-alternativo">
          <img src={icoHead} alt="imagenHead" className="head-new-splash"></img>
        </div>
      </div>
      <footer style={footerStyle}>
        <button style={buttonStyle}>
          <img src={logoPleizt} alt="Botón 1" style={imageStyle} />
        </button>
      </footer>
    </React.Fragment>
  );
};

const footerStyle = {
  background: "#28304A",
  color: "white",
  textAlign: "center",
  padding: "10px",
  position: "fixed",
  width: "100%",
  bottom: "0",
};

const buttonStyle = {
  margin: "0 10px",
  padding: "8px",
  border: "none",
  background: "transparent",
  cursor: "pointer",
};

const imageStyle = {
  height: "32px", // ajusta la altura según tus necesidades
};
