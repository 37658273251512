import React, { useState } from "react";
import "./MenuDesplegable.css";

import IcHome from "../Imagenes/ic_home.svg";
import IcoLocacion from "../Imagenes/Ico_Locacion.svg";
import IcOrder from "../Imagenes/ic_order.svg";
import imgPerfil from "../Imagenes/Ico_reserva.svg";
import IcLogout from "../Imagenes/ic_logout.svg";
import IcPlaces from "../Imagenes/ic_places.svg";
import IcAddress from "../Imagenes/ic_address.svg";
import IcoStart from "../Imagenes/Ico_Star.svg";
import { Link } from "react-router-dom";
const MenuDesplegable = (props) => {
  const { propiedadesCompany } = props;

  const [isOpen, setIsOpen] = useState(false);

  const nombreTienda = localStorage.getItem("nameTienda");
  const direccionUsuario = localStorage.getItem("Direccion");
  const tipoEntrega = localStorage.getItem("TipoEntrega");
  var idUser = localStorage.getItem("idUsuarioLogeado");

  const cerrarSesion = () => {
    localStorage.removeItem("idUsuarioLogeado");
    localStorage.removeItem("Complemento");
    localStorage.removeItem("mostrarPopUp");
    localStorage.removeItem("Metodo de pago");
    localStorage.removeItem("TipoEntrega");
    localStorage.removeItem("nameCiudadSucursal");
    localStorage.removeItem("Direccion");
    localStorage.removeItem("precioTotal");
    localStorage.removeItem("nameUsuarioLogeado");
    localStorage.removeItem("apellidoUsuarioLogeado");
    localStorage.removeItem("apellidoUsuarioLogeado");
    localStorage.removeItem("idTipoEnvio");
    localStorage.removeItem("Nombres");
    localStorage.removeItem("Apellidos");
    localStorage.removeItem("Celular");
    localStorage.removeItem("Correo");

    localStorage.removeItem("infoProductoSeleccionado");
  };

  return (
    <nav className="navbar">
      {(() => {
        if (idUser !== null) {
          return (
            <>
              <div
                className={`nav-icon-boton ${isOpen && "open"}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>

              <section className="container-nav-direccion">
                <Link to={"/misdirecciones"} style={{ textDecoration: "none" }}>
                  <div className="container-agregar-tarjeta">
                    <img src={IcoLocacion} alt="IcoLocacion"></img>
                    {(() => {
                      return <p className="subtitulos">{direccionUsuario} </p>;
                    })()}
                  </div>
                </Link>
              </section>

              <div className={`nav-items ${isOpen && "open"}`}>
                <img
                  src={propiedadesCompany.company_logo}
                  alt="logoCompany"
                  style={{
                    zIndex: "1",
                    width: "150px",
                    marginTop: "10px",
                    marginBottom: "30px",
                  }}
                />

                <Link to={"/"} style={{ textDecoration: "none" }}>
                  <div className="container-agregar-tarjeta">
                    <img src={IcHome} alt="Home"></img>
                    <p>Inicio</p>
                  </div>
                </Link>

                <Link to={"/perfil/"} style={{ textDecoration: "none" }}>
                  <div className="container-agregar-tarjeta">
                    <img src={imgPerfil} alt="Perfil"></img>
                    <p>Perfil</p>
                  </div>
                </Link>
                <Link
                  to={"/historialPedidos"}
                  style={{ textDecoration: "none" }}
                >
                  <div className="container-agregar-tarjeta">
                    <img src={IcOrder} alt="Ordenes"></img>
                    <p>Mis ordenes</p>
                  </div>
                </Link>
                {/* <Link to={"/listaOrdenes"} style={{ textDecoration: "none" }}>
                  {" "}
                  <p>Mis ordenes</p>{" "}
                </Link>
                
<Link to={'/listaTarjetas'} style={{textDecoration: 'none'}} > <p>Mis tarjetas</p>  </Link>
*/}
                <Link
                  to={"/misdirecciones/"}
                  style={{ textDecoration: "none" }}
                >
                  <div className="container-agregar-tarjeta">
                    <img src={IcAddress} alt="IcoLocacion"></img>
                    <p>Mis direcciones</p>
                  </div>
                </Link>

                <Link to={"/ListaTiendas"} style={{ textDecoration: "none" }}>
                  <div className="container-agregar-tarjeta">
                    <img src={IcPlaces} alt="IcoLocacion"></img>
                    <p>Tiendas</p>
                  </div>
                </Link>
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  <div className="container-agregar-tarjeta">
                    <img src={IcoStart} alt="IcoLocacion"></img>
                    <p>Mis puntos</p>
                  </div>
                </Link>

                <Link
                  to={"/"}
                  style={{ textDecoration: "none" }}
                  onClick={cerrarSesion}
                >
                  <div className="container-agregar-tarjeta">
                    <img src={IcLogout} alt="IcoLocacion"></img>
                    <p>Cerrar sesión</p>
                  </div>
                </Link>
              </div>
              <div className="nav-imagen-perfil">
                <img
                  className="imagen-perfil"
                  src={propiedadesCompany.company_logo}
                  alt=""
                ></img>
              </div>
            </>
          );
        } else {
          return (
            <>
              {(() => {
                var typeSend = localStorage?.getItem("typeSend");
                if (typeSend < 2) {
                  return (
                    <Link
                      to={"/IniciarSesion"}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className={`nav-icon-boton ${isOpen && "open"}`}
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Link>
                  );
                }
              })()}

              <section className="container-nav-direccion">
                <div className="container-agregar-tarjeta">
                  <img src={IcoLocacion} alt=""></img>
                  {(() => {
                    return <p className="subtitulos"> {direccionUsuario} </p>;
                  })()}
                </div>
              </section>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <div className="nav-imagen-perfil">
                  <img
                    className="imagen-perfil"
                    src={propiedadesCompany.company_logo}
                    alt=""
                  ></img>
                </div>
              </Link>
            </>
          );
        }
      })()}
    </nav>
  );
};

export default MenuDesplegable;
