import React, { useState, useRef } from "react";
import "./CarruselCategorias3.css";
import imgLoader from "../Imagenes/Pedidos.jpg";

import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import { Link } from "react-router-dom";
import { Link } from 'react-scroll';
export const CarrionCategory3 = (props) => {
    const { openCategories } = props;
    return (
        <div className="menu">
            <ul>
                {openCategories?.map((item, i) => {
                    return (<li>
                        <Link
                            activeClass="active"
                            to={item.uuid}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            {item.name}
                        </Link>
                    </li>);
                })}
            </ul>
        </div>
    );
};
